export const ALBERTO_BINI_EU_KZ_DB = {
    code: 'alberto_bini_europe', //eu, kz, ws, retail
    mainImage: require('../images/posters/alb.jpeg'),
    descriptions: [
        {
            language: 'en',
            description: 'Store of Women`s Clothes',
        },
        {
            language: 'kz',
            description: 'Мұнайшақ Мәйірім Дүкені',
        },
    ],

    storeProductTypes: [
        {
            code: 'Outerwear',
        },
        {
            code: 'Women clothes',
        },
    ],
    managers: [
        {
            firstName: 'Manager',
            lastName: '',
            emailAddress: 'salesnest.info@gmail.com',
            options: { manager: true },
            contacts: {
                emailAddress: '',
                phone: '+380983080085',
                viber: '+380983080085',
                whatsapp: '+380983080085',
                telegram: 'Albertobiniopt',
            },
        },
    ],
};
