export const INFORMATION = {
    en: {
        info_text_1:
            'Alberto Bini is a Ukrainian brand clothing manufacturer that has been creating high-quality women`s apparel for over 10 years. The production utilizes the latest materials and technologies, allowing for the creation of modern, high-quality, and practical clothing that is valued and in demand worldwide.',
        info_text_2: `1. PAYMENT:\n
             After placing an order, payment is made by transferring funds to the specified bank account.
             Payments are accepted to the bank account of the company "Alberto Bini".\n\n 
             2. DELIVERY:\n
             - FOR RETAIL ORDERS:\n
             Delivery is carried out within 2 to 15 working days.\n
             - FOR WHOLESALE ORDERS:\n
             Delivery time depends on the volume of the order, usually within 1 month.\n\n
             After placing the order, a manager will contact you to provide more detailed information about payment methods, bank account details, and delivery terms.`,
        info_text_3: `RETURNS:\n
             - We accept returns within 30 days of the purchase date.
             - Items must be unused, in their original packaging, and accompanied by a proof of purchase.
             - Return shipping costs are the responsibility of the customer unless the return is due to a product defect or error on our part.\n
             EXCHANGE:\n
             - Exchanges are permitted within 30 days of the purchase date.
             - Items eligible for exchange must be in unused condition and in their original packaging.
             - Exchange shipping costs are covered by the customer, unless the exchange is due to a product defect or our error.\n
             REFUNDS:\n
             - Refunds will be issued to the original form of payment within 7-10 business days after the returned item is received and inspected.
             - Shipping fees are non-refundable unless the return is due to a product defect or our error.
             - Please note that certain items may be subject to additional return restrictions. For further assistance or to initiate a return or exchange, please contact our customer service team.`,
        info_text_4: `1. GENERAL PROVISIONS\n
                  - 1.1. User's use of the site constitutes agreement with this Privacy Policy and the terms of the user's personal data processing.\n
                  - 1.2. In case of disagreement with the terms of the Privacy Policy, the user must stop using the site.\n
                  - 1.3. This Privacy Policy applies only to this site. The site administration does not control and is not responsible for third-party websites that the user may access through links available on the site.\n
                  - 1.4. The site administration does not verify the accuracy of personal data provided by the user.\n
                  \n
                  2. PRIVACY POLICY SUBJECT\n
                  - 2.1. This Privacy Policy establishes the obligations of the site administration to not disclose and to protect the privacy of personal data that the user provides at the request of the site administration.\n
                  - 2.2. Personal data permitted for processing under this Privacy Policy are provided by the user by filling out the registration form on the site.\n
                  \n
                  3. PURPOSES OF COLLECTING USER PERSONAL INFORMATION\n
                  - 3.1. The site administration may use the user's personal data for the purposes of:\n
                  - 3.1.1. Identifying the user to process an order.\n
                  - 3.1.2. Providing the user with access to personalized site resources.\n
                  - 3.1.3. Establishing contact with the user, including sending notifications, inquiries concerning the use of the site, provision of services, and processing user requests and applications.\n
                  - 3.1.4. Determining the user's location to ensure security and prevent fraud.\n
                  - 3.1.5. Confirming the accuracy and completeness of personal data provided by the user.\n
                  - 3.1.6. Informing the user of the site about the status of the order.\n
                  - 3.1.7. Providing effective customer and technical support in case of problems related to the use of the site.\n
                  - 3.1.8. Providing the user, with their consent, updates on products, special offers, pricing information, newsletters, and other information on behalf of the site or its partners.\n
                  - 3.1.9. Conducting advertising activities with the consent of the user.\n
                  - 3.1.10. Providing the user access to partner sites or services to obtain products, updates, and services.\n
                  \n
                  4. PARTIES' OBLIGATIONS\n
                  - 4.1. The user is obligated to:\n
                  - 4.1.1. Provide personal data necessary for using the site.\n
                  - 4.1.2. Update and supplement the provided personal data information in case of any changes.\n
                  - 4.2. The site administration is obligated to:\n
                  - 4.2.1. Use the information obtained exclusively for the purposes specified in clause 3 of this Privacy Policy.\n
                  - 4.2.2. Keep the confidential information secret, not disclose it without the user's prior written consent, and not sell, exchange, publish, or disclose the user's personal data by any other means.\n
                  - 4.2.3. Take precautions to protect the confidentiality of the user's personal data according to the procedure normally used to protect such information in existing business transactions.\n
                  - 4.2.4. Block personal data relating to the respective user from the moment the user or their legal representative or an authorized body for the protection of personal data subjects makes a request or inquiry, during the period of verification, in case of inaccurate personal data or unlawful actions.\n
                  \n
                  5. PARTIES' LIABILITY\n
                  - 5.1. The site administration, having failed to fulfill its obligations, is liable for losses incurred by the user due to the unlawful use of personal data, in accordance with the laws of Ukraine.\n
                  - 5.2. In the event of loss or disclosure of Confidential Information, the site administration is not responsible if such confidential information:\n
                  - 5.2.1. Became public domain before its loss or disclosure.\n
                  - 5.2.2. Was received from a third party before it was obtained by the site administration.\n
                  - 5.2.3. Was disclosed with the consent of the user.\n
                  \n
                  6. DISPUTE RESOLUTION\n
                  - 6.1. Before filing a lawsuit with disputes arising from the relations between the site user and the site administration, it is mandatory to submit a claim (a written proposal for voluntary dispute resolution).\n
                  - 6.2. The recipient of the claim must notify the claimant in writing of the results of the claim consideration within 30 calendar days from the day of receiving the claim.\n
                  - 6.3. If an agreement is not reached, the dispute will be referred to a judicial body in accordance with the current legislation of Ukraine.\n
                  \n
                  7. ADDITIONAL TERMS\n
                  - 7.1. The site administration has the right to make changes to this Privacy Policy without the user's consent.\n
                  - 7.2. The new Privacy Policy comes into effect from the moment it is posted on the e-commerce site, unless otherwise provided by the new edition of the Policy.`,
    },

    ua: {
        info_text_1:
            'Alberto Bini - це український бренд-виробник жіночого одягу, який вже понад 10 років створює якісний жіночий одяг. Виробництво використовує останні матеріали та технології, що дозволяє створювати сучасний, якісний та практичний одяг, який цінується та затребуваний у всьому світі.',
        info_text_2: `1. ОПЛАТА:\n
             Після оформлення замовлення оплата здійснюється шляхом перерахування коштів на вказаний банківський рахунок.
             Платежі приймаються на банківський рахунок компанії Alberto Bini.\n\n
             2. ДОСТАВКА:\n
             - ДЛЯ РОЗДРІБНИХ ЗАМОВЛЕНЬ:\n
             Доставка виконується протягом від 2 до 15 робочих днів.\n
             - ДЛЯ ОПТОВИХ ЗАМОВЛЕНЬ:\n
             Час доставки залежить від обсягу замовлення, зазвичай протягом 1 місяця.\n\n
             Після розміщення замовлення з вами зв'яжеться менеджер для надання детальнішої інформації про методи оплати, реквізити банківського рахунку та умови доставки.
        `,
        info_text_3: `ПОВЕРНЕННЯ:\n
             - Ми приймаємо повернення протягом 30 днів з дати покупки.
             - Товари мають бути не використаними, в оригінальній упаковці та з доказом покупки.
             - Витрати на повернення доставки лежать на покупцеві, якщо повернення не пов'язане з дефектом товару або помилкою з нашого боку.\n
             ОБМІН:\n
             - Обмін допускається протягом 30 днів з дати покупки.
             - Товари, допустимі до обміну, повинні бути не використаними та в оригінальній упаковці.
             - Витрати на доставку обміну покриває покупець, якщо обмін не пов'язаний з дефектом товару або нашою помилкою.\n
             ПОВЕРНЕННЯ КОШТІВ:\n
             - Повернення коштів здійснюється на первісну форму оплати протягом 7-10 робочих днів після отримання та перевірки повернутого товару.
             - Витрати на доставку не повертаються, якщо повернення не пов'язане з дефектом товару або нашою помилкою.
             - Зверніть увагу, що деякі товари можуть підлягати додатковим обмеженням на повернення. Для отримання додаткової допомоги або для початку процесу повернення або обміну, будь ласка, зверніться до нашої служби підтримки клієнтів.`,
        info_text_4: `1. ЗАГАЛЬНІ ПОЛОЖЕННЯ\n
             - 1.1. Використання сайту користувачем є згодою з цією Політикою конфіденційності та умовами обробки особистих даних користувача.\n
             - 1.2. У разі незгоди з умовами Політики конфіденційності користувач має припинити використання сайту.\n
             - 1.3. Ця Політика конфіденційності застосовується лише до цього сайту. Адміністрація сайту не контролює та не несе відповідальності за сторонні вебсайти, до яких користувач може отримати доступ через посилання, доступні на сайті.\n
             - 1.4. Адміністрація сайту не перевіряє достовірність особистих даних, наданих користувачем.\n
             \n
             2. ПРЕДМЕТ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ\n
             - 2.1. Ця Політика конфіденційності встановлює обов'язки адміністрації сайту не розголошувати та захищати конфіденційність особистих даних, які користувач надає за запитом адміністрації сайту.\n
             - 2.2. Особисті дані, дозволені для обробки за цією Політикою конфіденційності, надаються користувачем шляхом заповнення реєстраційної форми на сайті.\n
             \n
             3. МЕТИ ЗБОРУ ОСОБИСТОЇ ІНФОРМАЦІЇ КОРИСТУВАЧА\n
             - 3.1. Адміністрація сайту може використовувати особисті дані користувача з метою:\n
             - 3.1.1. Ідентифікації користувача для обробки замовлення.\n
             - 3.1.2. Забезпечення користувачу доступу до персоналізованих ресурсів сайту.\n
             - 3.1.3. Налагодження контакту з користувачем, включаючи відправлення повідомлень, запитів щодо використання сайту, надання послуг та обробки запитів та заявок користувача.\n
             - 3.1.4. Визначення місцезнаходження користувача для забезпечення безпеки та запобігання шахрайству.\n
             - 3.1.5. Підтвердження достовірності та повноти особистих даних, наданих користувачем.\n
             - 3.1.6. Інформування користувача сайту про статус замовлення.\n
             - 3.1.7. Забезпечення ефективної клієнтської та технічної підтримки у разі проблем, пов'язаних з використанням сайту.\n
             - 3.1.8. Надання користувачу, за його згодою, оновлень продуктів, спеціальних пропозицій, інформації про ціни, новинних бюлетенів та іншої інформації від імені сайту або його партнерів.\n
             - 3.1.9. Проведення рекламних заходів за згодою користувача.\n
             - 3.1.10. Надання користувачу доступу до сайтів або послуг партнерів для отримання продуктів, оновлень і послуг.\n
             \n
             4. ОБОВ'ЯЗКИ СТОРІН\n
             - 4.1. Користувач зобов'язаний:\n
             - 4.1.1. Надати особисті дані, необхідні для використання сайту.\n
             - 4.1.2. Оновлювати та доповнювати надану інформацію про особисті дані у разі будь-яких змін.\n
             - 4.2. Адміністрація сайту зобов'язана:\n
             - 4.2.1. Використовувати отриману інформацію виключно для цілей, вказаних у пункті 3 цієї Політики конфіденційності.\n
             - 4.2.2. Зберігати в таємниці конфіденційну інформацію, не розголошувати її без попередньої письмової згоди користувача та не продавати, не обмінювати, не публікувати або розголошувати особисті дані користувача будь-яким іншим способом.\n
             - 4.2.3. Вживати заходів обережності для захисту конфіденційності особистих даних користувача відповідно до процедури, яка зазвичай використовується для захисту такої інформації у наявних ділових операціях.\n
             - 4.2.4. Блокувати особисті дані, що стосуються відповідного користувача, з моменту звернення користувача або його законного представника чи уповноваженого органу з захисту прав суб'єктів особистих даних, під час періоду перевірки, у разі неточних особистих даних або протиправних дій.\n
             \n
             5. ВІДПОВІДАЛЬНІСТЬ СТОРІН\n
             - 5.1. Адміністрація сайту, яка не виконала свої зобов'язання, несе відповідальність за збитки, завдані користувачу через незаконне використання особистих даних, відповідно до законодавства України.\n
             - 5.2. У разі втрати або розголошення Конфіденційної інформації адміністрація сайту не несе відповідальності, якщо така конфіденційна інформація:\n
             - 5.2.1. Стала загальнодоступною до її втрати або розголошення.\n
             - 5.2.2. Була отримана від третьої сторони до того, як її отримала адміністрація сайту.\n
             - 5.2.3. Була розголошена за згодою користувача.\n
             \n
             6. ВИРІШЕННЯ СПОРІВ\n
             - 6.1. Перед поданням позову зі спорів, що виникають з відносин між користувачем сайту та адміністрацією сайту, обов'язково подати претензію (письмову пропозицію про добровільне врегулювання спору).\n
             - 6.2. Отримувач претензії повинен повідомити заявника письмово про результати розгляду претензії протягом 30 календарних днів з дня її отримання.\n
             - 6.3. Якщо домовленість не досягнута, спір направляється до судового органу відповідно до чинного законодавства України.\n
             \n
             7. ДОДАТКОВІ УМОВИ\n
             - 7.1. Адміністрація сайту має право вносити зміни до цієї Політики конфіденційності без згоди користувача.\n
             - 7.2. Нова Політика конфіденційності набуває чинності з моменту її розміщення на сайті електронної комерції, якщо інше не передбачено новою редакцією Політики.`,
    },
    fr: {
        info_text_1:
            'Alberto Bini est une marque de vêtements ukrainienne qui fabrique des vêtements féminins de haute qualité depuis plus de 10 ans. La production utilise les matériaux et les technologies les plus récents, permettant de créer des vêtements modernes, de qualité et pratiques, appréciés et demandés dans le monde entier.',
        info_text_2: `1. PAIEMENT :\n
          Après avoir passé une commande, le paiement est effectué par virement sur le compte bancaire spécifié.
          Les paiements sont acceptés sur le compte bancaire de la société "Alberto Bini".\n\n 
          2. LIVRAISON :\n
          - POUR LES COMMANDES AU DÉTAIL :\n
          La livraison est effectuée sous 2 à 15 jours ouvrables.\n
          - POUR LES COMMANDES EN GROS :\n
          Le délai de livraison dépend du volume de la commande, généralement dans un délai d'un mois.\n\n
          Après la passation de la commande, un responsable vous contactera pour fournir des informations plus détaillées sur les méthodes de paiement, les détails du compte bancaire et les conditions de livraison.`,
        info_text_3: `RETOURS :\n
          - Nous acceptons les retours dans les 30 jours suivant la date d'achat.
          - Les articles doivent être inutilisés, dans leur emballage d'origine et accompagnés d'une preuve d'achat.
          - Les frais de retour sont à la charge du client, sauf si le retour est dû à un défaut du produit ou à une erreur de notre part.\n
          ÉCHANGES :\n
          - Les échanges sont autorisés dans les 30 jours suivant la date d'achat.
          - Les articles éligibles à l'échange doivent être inutilisés et dans leur emballage d'origine.
          - Les frais d'expédition des échanges sont à la charge du client, sauf en cas de défaut du produit ou d'erreur de notre part.\n
          REMBOURSEMENTS :\n
          - Les remboursements seront effectués sur la forme originale de paiement dans les 7 à 10 jours ouvrables après la réception et l'inspection de l'article retourné.
          - Les frais d'expédition ne sont pas remboursables, sauf si le retour est dû à un défaut du produit ou à une erreur de notre part.
          - Veuillez noter que certains articles peuvent être soumis à des restrictions de retour supplémentaires. Pour plus d'assistance ou pour initier un retour ou un échange, veuillez contacter notre service clientèle.`,
        info_text_4: `1. DISPOSITIONS GÉNÉRALES\n
               - 1.1. L'utilisation du site par l'utilisateur constitue un accord avec cette Politique de Confidentialité et les termes du traitement des données personnelles de l'utilisateur.\n
               - 1.2. En cas de désaccord avec les termes de la Politique de Confidentialité, l'utilisateur doit cesser d'utiliser le site.\n
               - 1.3. Cette Politique de Confidentialité s'applique uniquement à ce site. L'administration du site ne contrôle pas et n'est pas responsable des sites tiers auxquels l'utilisateur peut accéder via des liens disponibles sur le site.\n
               - 1.4. L'administration du site ne vérifie pas l'exactitude des données personnelles fournies par l'utilisateur.\n
               \n
               2. OBJET DE LA POLITIQUE DE CONFIDENTIALITÉ\n
               - 2.1. Cette Politique de Confidentialité établit les obligations de l'administration du site de ne pas divulguer et de protéger la confidentialité des données personnelles que l'utilisateur fournit à la demande de l'administration du site.\n
               - 2.2. Les données personnelles autorisées pour le traitement sous cette Politique de Confidentialité sont fournies par l'utilisateur en remplissant le formulaire d'inscription sur le site.\n
               \n
               3. OBJECTIFS DE LA COLLECTE DES INFORMATIONS PERSONNELLES DE L'UTILISATEUR\n
               - 3.1. L'administration du site peut utiliser les données personnelles de l'utilisateur à des fins de :\n
               - 3.1.1. Identifier l'utilisateur pour traiter une commande.\n
               - 3.1.2. Fournir à l'utilisateur un accès aux ressources personnalisées du site.\n
               - 3.1.3. Établir un contact avec l'utilisateur, y compris l'envoi de notifications, des demandes concernant l'utilisation du site, la fourniture de services, et le traitement des demandes et des applications de l'utilisateur.\n
               - 3.1.4. Déterminer la localisation de l'utilisateur pour assurer la sécurité et prévenir la fraude.\n
               - 3.1.5. Confirmer l'exactitude et la complétude des données personnelles fournies par l'utilisateur.\n
               - 3.1.6. Informer l'utilisateur du site sur l'état de la commande.\n
               - 3.1.7. Fournir un soutien efficace au client et un support technique en cas de problèmes liés à l'utilisation du site.\n
               - 3.1.8. Fournir à l'utilisateur, avec son consentement, des mises à jour sur les produits, des offres spéciales, des informations sur les prix, des bulletins d'information et d'autres informations au nom du site ou de ses partenaires.\n
               - 3.1.9. Mener des activités publicitaires avec le consentement de l'utilisateur.\n
               - 3.1.10. Fournir à l'utilisateur un accès aux sites ou services des partenaires pour obtenir des produits, des mises à jour et des services.\n
               \n
               4. OBLIGATIONS DES PARTIES\n
               - 4.1. L'utilisateur est tenu de :\n
               - 4.1.1. Fournir les données personnelles nécessaires pour utiliser le site.\n
               - 4.1.2. Mettre à jour et compléter les informations de données personnelles fournies en cas de changements.\n
               - 4.2. L'administration du site est tenue de :\n
               - 4.2.1. Utiliser les informations obtenues exclusivement pour les fins spécifiées au point 3 de cette Politique de Confidentialité.\n
               - 4.2.2. Garder les informations confidentielles secrètes, ne pas les divulguer sans le consentement préalable écrit de l'utilisateur, et ne pas vendre, échanger, publier, ou divulguer les données personnelles de l'utilisateur de toute autre manière.\n
               - 4.2.3. Prendre des précautions pour protéger la confidentialité des données personnelles de l'utilisateur selon la procédure normalement utilisée pour protéger de telles informations dans les transactions commerciales existantes.\n
               - 4.2.4. Bloquer les données personnelles relatives à l'utilisateur respectif dès le moment où l'utilisateur ou son représentant légal ou un organe autorisé pour la protection des sujets de données personnelles fait une demande ou une enquête, pendant la période de vérification, en cas de données personnelles inexactes ou d'actions illégales.\n
               \n
               5. RESPONSABILITÉ DES PARTIES\n
               - 5.1. L'administration du site, en ne remplissant pas ses obligations, est responsable des pertes subies par l'utilisateur en raison de l'utilisation illégale des données personnelles, conformément aux lois de l'Ukraine.\n
               - 5.2. En cas de perte ou de divulgation d'Informations Confidentielles, l'administration du site n'est pas responsable si ces informations confidentielles :\n
               - 5.2.1. Étaient devenues publiques avant leur perte ou leur divulgation.\n
               - 5.2.2. Ont été reçues d'un tiers avant leur acquisition par l'administration du site.\n
               - 5.2.3. Ont été divulguées avec le consentement de l'utilisateur.\n
               \n
               6. RÉSOLUTION DES DIFFÉRENDS\n
               - 6.1. Avant de déposer une plainte concernant des différends découlant des relations entre l'utilisateur du site et l'administration du site, il est obligatoire de soumettre une réclamation (une proposition écrite pour une résolution volontaire du différend).\n
               - 6.2. Le destinataire de la réclamation doit notifier par écrit au plaignant les résultats de l'examen de la réclamation dans les 30 jours calendaires à compter du jour de la réception de la réclamation.\n
               - 6.3. Si aucun accord n'est atteint, le différend sera renvoyé à un organe judiciaire conformément à la législation en vigueur en Ukraine.\n
               \n
               7. TERMES ADDITIONNELS\n
               - 7.1. L'administration du site a le droit de faire des modifications à cette Politique de Confidentialité sans le consentement de l'utilisateur.\n
               - 7.2. La nouvelle Politique de Confidentialité prend effet dès sa publication sur le site de commerce électronique, sauf disposition contraire de la nouvelle édition de la Politique.`,
    },
    es: {
        info_text_1:
            'Alberto Bini es un fabricante de ropa de marca ucraniana que ha estado creando prendas de vestir femeninas de alta calidad durante más de 10 años. La producción utiliza los materiales y tecnologías más recientes, permitiendo la creación de ropa moderna, de alta calidad y práctica que es valorada y demandada en todo el mundo.',
        info_text_2: `1. PAGO:\n
          Tras realizar un pedido, el pago se efectúa mediante transferencia de fondos a la cuenta bancaria especificada.
          Se aceptan pagos en la cuenta bancaria de la empresa "Alberto Bini".\n\n 
          2. ENTREGA:\n
          - PARA PEDIDOS AL POR MENOR:\n
          La entrega se realiza dentro de los 2 a 15 días hábiles.\n
          - PARA PEDIDOS AL POR MAYOR:\n
          El tiempo de entrega depende del volumen del pedido, generalmente dentro de 1 mes.\n\n
          Después de realizar el pedido, un gerente se pondrá en contacto contigo para proporcionar información más detallada sobre métodos de pago, detalles de la cuenta bancaria y términos de entrega.`,
        info_text_3: `DEVOLUCIONES:\n
          - Aceptamos devoluciones dentro de los 30 días posteriores a la fecha de compra.
          - Los artículos deben estar sin usar, en su embalaje original y acompañados de un comprobante de compra.
          - Los costos de envío de la devolución corren por cuenta del cliente, a menos que la devolución sea debido a un defecto del producto o un error de nuestra parte.\n
          CAMBIOS:\n
          - Los cambios están permitidos dentro de los 30 días posteriores a la fecha de compra.
          - Los artículos elegibles para cambio deben estar sin usar y en su embalaje original.
          - Los costos de envío del cambio corren por cuenta del cliente, a menos que el cambio se deba a un defecto del producto o un error nuestro.\n
          REEMBOLSOS:\n
          - Los reembolsos se realizarán al método de pago original dentro de los 7-10 días hábiles después de que el artículo devuelto sea recibido e inspeccionado.
          - Los gastos de envío no son reembolsables, a menos que la devolución se deba a un defecto del producto o un error nuestro.
          - Tenga en cuenta que algunos artículos pueden estar sujetos a restricciones adicionales de devolución. Para más asistencia o para iniciar una devolución o cambio, por favor contacte a nuestro equipo de servicio al cliente.`,
        info_text_4: `1. DISPOSICIONES GENERALES\n
               - 1.1. El uso del sitio por parte del usuario constituye un acuerdo con esta Política de Privacidad y los términos del procesamiento de datos personales del usuario.\n
               - 1.2. En caso de desacuerdo con los términos de la Política de Privacidad, el usuario debe dejar de usar el sitio.\n
               - 1.3. Esta Política de Privacidad se aplica solo a este sitio. La administración del sitio no controla y no es responsable de los sitios web de terceros a los que el usuario puede acceder a través de enlaces disponibles en el sitio.\n
               - 1.4. La administración del sitio no verifica la precisión de los datos personales proporcionados por el usuario.\n
               \n
               2. OBJETO DE LA POLÍTICA DE PRIVACIDAD\n
               - 2.1. Esta Política de Privacidad establece las obligaciones de la administración del sitio de no divulgar y proteger la privacidad de los datos personales que el usuario proporciona a petición de la administración del sitio.\n
               - 2.2. Los datos personales permitidos para el procesamiento bajo esta Política de Privacidad son proporcionados por el usuario al completar el formulario de registro en el sitio.\n
               \n
               3. PROPÓSITOS DE LA RECOLECCIÓN DE INFORMACIÓN PERSONAL DEL USUARIO\n
               - 3.1. La administración del sitio puede usar los datos personales del usuario con los fines de:\n
               - 3.1.1. Identificar al usuario para procesar un pedido.\n
               - 3.1.2. Proporcionar al usuario acceso a recursos personalizados del sitio.\n
               - 3.1.3. Establecer contacto con el usuario, incluyendo el envío de notificaciones, consultas sobre el uso del sitio, provisión de servicios, y procesamiento de solicitudes y aplicaciones del usuario.\n
               - 3.1.4. Determinar la ubicación del usuario para asegurar la seguridad y prevenir el fraude.\n
               - 3.1.5. Confirmar la precisión y la integridad de los datos personales proporcionados por el usuario.\n
               - 3.1.6. Informar al usuario del sitio sobre el estado del pedido.\n
               - 3.1.7. Proporcionar apoyo al cliente y soporte técnico efectivos en caso de problemas relacionados con el uso del sitio.\n
               - 3.1.8. Proporcionar al usuario, con su consentimiento, actualizaciones sobre productos, ofertas especiales, información de precios, boletines informativos y otra información en nombre del sitio o de sus socios.\n
               - 3.1.9. Realizar actividades publicitarias con el consentimiento del usuario.\n
               - 3.1.10. Proporcionar al usuario acceso a sitios o servicios de socios para obtener productos, actualizaciones y servicios.\n
               \n
               4. OBLIGACIONES DE LAS PARTES\n
               - 4.1. El usuario está obligado a:\n
               - 4.1.1. Proporcionar los datos personales necesarios para usar el sitio.\n
               - 4.1.2. Actualizar y complementar la información de datos personales proporcionada en caso de cambios.\n
               - 4.2. La administración del sitio está obligada a:\n
               - 4.2.1. Usar la información obtenida exclusivamente para los fines especificados en el punto 3 de esta Política de Privacidad.\n
               - 4.2.2. Mantener la información confidencial en secreto, no divulgarla sin el consentimiento previo por escrito del usuario y no vender, intercambiar, publicar, o divulgar los datos personales del usuario de ninguna otra manera.\n
               - 4.2.3. Tomar precauciones para proteger la confidencialidad de los datos personales del usuario de acuerdo con el procedimiento normalmente utilizado para proteger dicha información en transacciones comerciales existentes.\n
               - 4.2.4. Bloquear los datos personales relativos al usuario respectivo desde el momento en que el usuario o su representante legal o un órgano autorizado para la protección de los sujetos de datos personales realice una solicitud o consulta, durante el período de verificación, en caso de datos personales inexactos o acciones ilegales.\n
               \n
               5. RESPONSABILIDAD DE LAS PARTES\n
               - 5.1. La administración del sitio, al no cumplir con sus obligaciones, es responsable de las pérdidas incurridas por el usuario debido al uso ilegal de datos personales, de acuerdo con las leyes de Ucrania.\n
               - 5.2. En caso de pérdida o divulgación de Información Confidencial, la administración del sitio no es responsable si dicha información confidencial:\n
               - 5.2.1. Se hizo de dominio público antes de su pérdida o divulgación.\n
               - 5.2.2. Fue recibida de un tercero antes de que fuera obtenida por la administración del sitio.\n
               - 5.2.3. Fue divulgada con el consentimiento del usuario.\n
               \n
               6. RESOLUCIÓN DE DISPUTAS\n
               - 6.1. Antes de presentar una demanda con disputas que surjan de las relaciones entre el usuario del sitio y la administración del sitio, es obligatorio presentar un reclamo (una propuesta escrita para la resolución voluntaria de la disputa).\n
               - 6.2. El receptor del reclamo debe notificar al reclamante por escrito los resultados de la consideración del reclamo dentro de los 30 días calendario desde el día de recepción del reclamo.\n
               - 6.3. Si no se alcanza un acuerdo, la disputa será referida a un órgano judicial de acuerdo con la legislación vigente de Ucrania.\n
               \n
               7. TÉRMINOS ADICIONALES\n
               - 7.1. La administración del sitio tiene derecho a realizar cambios en esta Política de Privacidad sin el consentimiento del usuario.\n
               - 7.2. La nueva Política de Privacidad entra en vigor en el momento de su publicación en el sitio de comercio electrónico, a menos que la nueva edición de la Política disponga lo contrario.`,
    },
    pl: {
        info_text_1:
            'Alberto Bini to ukraiński producent odzieży, który od ponad 10 lat tworzy wysokiej jakości odzież damską. Produkcja wykorzystuje najnowsze materiały i technologie, co pozwala na tworzenie nowoczesnych, wysokiej jakości i praktycznych ubrań, które są cenione i poszukiwane na całym świecie.',
        info_text_2: `1. PŁATNOŚĆ:\n
          Po złożeniu zamówienia płatność realizowana jest przez przelew na wskazane konto bankowe.
          Płatności przyjmowane są na konto bankowe firmy "Alberto Bini".\n\n 
          2. DOSTAWA:\n
          - DLA ZAMÓWIEŃ DETALICZNYCH:\n
          Dostawa realizowana jest w ciągu 2 do 15 dni roboczych.\n
          - DLA ZAMÓWIEŃ HURTOWYCH:\n
          Czas dostawy zależy od wielkości zamówienia, zwykle w ciągu 1 miesiąca.\n\n
          Po złożeniu zamówienia menedżer skontaktuje się z Tobą, aby udzielić bardziej szczegółowych informacji na temat metod płatności, danych konta bankowego i warunków dostawy.`,
        info_text_3: `ZWROTY:\n
          - Przyjmujemy zwroty w ciągu 30 dni od daty zakupu.
          - Przedmioty muszą być nieużywane, w oryginalnym opakowaniu i z dowodem zakupu.
          - Koszty przesyłki zwrotnej pokrywa klient, chyba że zwrot jest wynikiem wady produktu lub błędu z naszej strony.\n
          WYMIANA:\n
          - Wymiany są dozwolone w ciągu 30 dni od daty zakupu.
          - Przedmioty kwalifikujące się do wymiany muszą być nieużywane i w oryginalnym opakowaniu.
          - Koszty wysyłki towaru wymienionego ponosi klient, chyba że wymiana jest wynikiem wady produktu lub naszego błędu.\n
          ZWROTY PIENIĘDZY:\n
          - Zwroty pieniędzy zostaną przekazane na pierwotną formę płatności w ciągu 7-10 dni roboczych po otrzymaniu i sprawdzeniu zwróconego towaru.
          - Opłaty za wysyłkę nie podlegają zwrotowi, chyba że zwrot jest wynikiem wady produktu lub naszego błędu.
          - Należy zauważyć, że niektóre przedmioty mogą podlegać dodatkowym ograniczeniom zwrotów. W celu uzyskania dalszej pomocy lub rozpoczęcia procesu zwrotu lub wymiany, skontaktuj się z naszym zespołem obsługi klienta.`,
        info_text_4: `1. POSTANOWIENIA OGÓLNE\n
               - 1.1. Korzystanie z witryny przez użytkownika oznacza zgody na tę Politykę Prywatności oraz warunki przetwarzania danych osobowych użytkownika.\n
               - 1.2. W przypadku niezgody na warunki Polityki Prywatności, użytkownik powinien zaprzestać korzystania z witryny.\n
               - 1.3. Niniejsza Polityka Prywatności dotyczy tylko tej witryny. Administracja witryny nie kontroluje i nie odpowiada za strony internetowe osób trzecich, do których użytkownik może uzyskać dostęp za pośrednictwem dostępnych linków na witrynie.\n
               - 1.4. Administracja witryny nie weryfikuje prawdziwości danych osobowych podanych przez użytkownika.\n
               \n
               2. PRZEDMIOT POLITYKI PRYWATNOŚCI\n
               - 2.1. Polityka Prywatności określa zobowiązania administracji witryny do nieujawniania i ochrony prywatności danych osobowych, które użytkownik podaje na żądanie administracji witryny.\n
               - 2.2. Dane osobowe dopuszczone do przetwarzania w ramach niniejszej Polityki Prywatności są dostarczane przez użytkownika poprzez wypełnienie formularza rejestracyjnego na witrynie.\n
               \n
               3. CELE ZBIERANIA DANYCH OSOBOWYCH UŻYTKOWNIKA\n
               - 3.1. Administracja witryny może używać danych osobowych użytkownika w celu:\n
               - 3.1.1. Identyfikacji użytkownika w celu przetworzenia zamówienia.\n
               - 3.1.2. Zapewnienia użytkownikowi dostępu do spersonalizowanych zasobów witryny.\n
               - 3.1.3. Nawiązywania kontaktu z użytkownikiem, w tym wysyłania powiadomień, zapytań dotyczących korzystania z witryny, świadczenia usług, oraz przetwarzania żądań i aplikacji użytkownika.\n
               - 3.1.4. Określenia lokalizacji użytkownika w celu zapewnienia bezpieczeństwa i zapobiegania oszustwom.\n
               - 3.1.5. Potwierdzenia dokładności i kompletności danych osobowych podanych przez użytkownika.\n
               - 3.1.6. Informowania użytkownika witryny o stanie zamówienia.\n
               - 3.1.7. Zapewnienia skutecznej obsługi klienta i wsparcia technicznego w przypadku problemów związanych z używaniem witryny.\n
               - 3.1.8. Dostarczania użytkownikowi, za jego zgodą, aktualizacji o produktach, specjalnych ofertach, informacji o cenach, newsletterach i innych informacjach w imieniu witryny lub jej partnerów.\n
               - 3.1.9. Prowadzenia działań reklamowych za zgodą użytkownika.\n
               - 3.1.10. Zapewnienia użytkownikowi dostępu do witryn lub usług partnerów w celu uzyskania produktów, aktualizacji i usług.\n
               \n
               4. ZOBOWIĄZANIA STRON\n
               - 4.1. Użytkownik jest zobowiązany:\n
               - 4.1.1. Dostarczyć dane osobowe niezbędne do użytkowania witryny.\n
               - 4.1.2. Aktualizować i uzupełniać dostarczone informacje o danych osobowych w przypadku jakichkolwiek zmian.\n
               - 4.2. Administracja witryny jest zobowiązana:\n
               - 4.2.1. Używać informacji uzyskanych wyłącznie w celach określonych w punkcie 3 niniejszej Polityki Prywatności.\n
               - 4.2.2. Zachować w tajemnicy poufne informacje, nie ujawniać ich bez uprzedniej pisemnej zgody użytkownika, ani nie sprzedawać, wymieniać, publikować ani w żaden inny sposób ujawniać danych osobowych użytkownika.\n
               - 4.2.3. Podejmować środki ostrożności w celu ochrony poufności danych osobowych użytkownika zgodnie z procedurami zwykle stosowanymi do ochrony takich informacji w istniejących transakcjach biznesowych.\n
               - 4.2.4. Blokować dane osobowe dotyczące odpowiedniego użytkownika od momentu, gdy użytkownik lub jego prawny przedstawiciel lub uprawniony organ ochrony danych osobowych zgłosi żądanie lub zapytanie, w okresie weryfikacji, w przypadku nieprawidłowych danych osobowych lub działania bezprawne.\n
               \n
               5. ODPOWIEDZIALNOŚĆ STRON\n
               - 5.1. Administracja witryny, nie wypełniając swoich zobowiązań, odpowiada za straty poniesione przez użytkownika w wyniku bezprawnego wykorzystania danych osobowych, zgodnie z przepisami prawa Ukrainy.\n
               - 5.2. W przypadku utraty lub ujawnienia Poufnych Informacji, administracja witryny nie ponosi odpowiedzialności, jeśli takie poufne informacje:\n
               - 5.2.1. Stały się ogólnodostępne przed ich utratą lub ujawnieniem.\n
               - 5.2.2. Zostały otrzymane od strony trzeciej przed ich uzyskaniem przez administrację witryny.\n
               - 5.2.3. Zostały ujawnione za zgodą użytkownika.\n
               \n
               6. ROZSTRZYGANIE SPORÓW\n
               - 6.1. Przed wniesieniem pozwu w sporach wynikających z relacji między użytkownikiem witryny a administracją witryny, należy złożyć roszczenie (pisemną propozycję dobrowolnego rozstrzygnięcia sporu).\n
               - 6.2. Odbiorca roszczenia powinien powiadomić skarżącego na piśmie o wynikach rozpatrzenia roszczenia w ciągu 30 dni kalendarzowych od dnia otrzymania roszczenia.\n
               - 6.3. Jeżeli nie osiągnięto porozumienia, spór zostanie skierowany do organu sądowego zgodnie z obowiązującym prawem Ukrainy.\n
               \n
               7. DODATKOWE WARUNKI\n
               - 7.1. Administracja witryny ma prawo wprowadzać zmiany do tej Polityki Prywatności bez zgody użytkownika.\n
               - 7.2. Nowa Polityka Prywatności wchodzi w życie z chwilą jej opublikowania na stronie e-commerce, chyba że nowa edycja Polityki stanowi inaczej.`,
    },
    cz: {
        info_text_1:
            'Alberto Bini je ukrajinský výrobce oděvů, který již více než 10 let vyrábí vysoce kvalitní dámské oblečení. Výroba využívá nejnovější materiály a technologie, což umožňuje vytváření moderního, kvalitního a praktického oblečení, které je ceněné a žádané po celém světě.',
        info_text_2: `1. PLATBA:\n
          Po zadání objednávky se platba provádí převodem na uvedený bankovní účet.
          Platby jsou přijímány na bankovní účet společnosti "Alberto Bini".\n\n 
          2. DODÁNÍ:\n
          - PRO MALOOBCHODNÍ OBJEDNÁVKY:\n
          Dodání probíhá do 2 až 15 pracovních dnů.\n
          - PRO VELKOOBCHODNÍ OBJEDNÁVKY:\n
          Doba dodání závisí na objemu objednávky, obvykle do 1 měsíce.\n\n
          Po zadání objednávky vás kontaktuje manažer, který poskytne podrobnější informace o způsobech platby, údajích o bankovním účtu a podmínkách dodání.`,
        info_text_3: `VRÁCENÍ:\n
          - Vrácení zboží přijímáme do 30 dnů od data nákupu.
          - Zboží musí být nepoužité, v původním balení a doprovázené dokladem o nákupu.
          - Náklady na vrácení zboží hradí zákazník, pokud není vrácení způsobeno vadou produktu nebo chybou na naší straně.\n
          VÝMĚNA:\n
          - Výměny jsou povoleny do 30 dnů od data nákupu.
          - Zboží způsobilé pro výměnu musí být nepoužité a v původním balení.
          - Náklady na zaslání zboží pro výměnu hradí zákazník, pokud výměna není způsobena vadou produktu nebo naší chybou.\n
          VRAŤENÍ PENĚZ:\n
          - Vrácení peněz proběhne na původní způsob platby do 7-10 pracovních dnů po přijetí a kontrole vráceného zboží.
          - Poplatky za dopravu nejsou vratné, pokud vrácení není způsobeno vadou produktu nebo naší chybou.
          - Upozorňujeme, že některé položky mohou být předmětem dalších omezení vrácení. Pro další pomoc nebo zahájení vrácení nebo výměny kontaktujte náš zákaznický servis.`,
        info_text_4: `1. OBECNÁ USTANOVENÍ\n
               - 1.1. Používání webu uživatelem znamená souhlas s touto Zásadou ochrany osobních údajů a podmínkami zpracování osobních údajů uživatele.\n
               - 1.2. V případě nesouhlasu s podmínkami Zásad ochrany osobních údajů musí uživatel přestat web používat.\n
               - 1.3. Tato Zásada ochrany osobních údajů se vztahuje pouze na tento web. Správa webu nekontroluje a nenese odpovědnost za webové stránky třetích stran, na které může uživatel přistupovat prostřednictvím odkazů dostupných na webu.\n
               - 1.4. Správa webu neověřuje přesnost osobních údajů poskytnutých uživatelem.\n
               \n
               2. PŘEDMĚT ZÁSAD OCHRANY OSOBNÍCH ÚDAJŮ\n
               - 2.1. Tyto Zásady ochrany osobních údajů stanovují závazky správy webu nezveřejňovat a chránit soukromí osobních údajů, které uživatel poskytuje na žádost správy webu.\n
               - 2.2. Osobní údaje povolené pro zpracování podle těchto Zásad ochrany osobních údajů jsou poskytovány uživatelem vyplněním registračního formuláře na webu.\n
               \n
               3. ÚČELY SBÍRÁNÍ OSOBNÍCH ÚDAJŮ UŽIVATELE\n
               - 3.1. Správa webu může použít osobní údaje uživatele za účelem:\n
               - 3.1.1. Identifikace uživatele při zpracování objednávky.\n
               - 3.1.2. Poskytnutí uživateli přístupu k personalizovaným zdrojům webu.\n
               - 3.1.3. Navázání kontaktu s uživatelem, včetně zasílání oznámení, dotazů týkajících se používání webu, poskytování služeb a zpracování žádostí a aplikací uživatele.\n
               - 3.1.4. Určení polohy uživatele za účelem zajištění bezpečnosti a prevence podvodů.\n
               - 3.1.5. Potvrzení přesnosti a úplnosti osobních údajů poskytnutých uživatelem.\n
               - 3.1.6. Informování uživatele webu o stavu objednávky.\n
               - 3.1.7. Poskytování účinné zákaznické a technické podpory v případě problémů souvisejících s používáním webu.\n
               - 3.1.8. Poskytování uživateli, se souhlasem, aktualizací o produktech, speciálních nabídkách, informacích o cenách, novinkách a dalších informacích jménem webu nebo jeho partnerů.\n
               - 3.1.9. Provádění reklamních aktivit se souhlasem uživatele.\n
               - 3.1.10. Poskytování uživateli přístupu k partnerským webům nebo službám za účelem získání produktů, aktualizací a služeb.\n
               \n
               4. ZÁVAZKY STRAN\n
               - 4.1. Uživatel je povinen:\n
               - 4.1.1. Poskytnout osobní údaje potřebné pro používání webu.\n
               - 4.1.2. Aktualizovat a doplňovat poskytnuté osobní údajové informace v případě jakýchkoli změn.\n
               - 4.2. Správa webu je povinna:\n
               - 4.2.1. Používat získané informace výhradně pro účely uvedené v bodu 3 těchto Zásad ochrany osobních údajů.\n
               - 4.2.2. Uchovávat důvěrné informace v tajnosti, nezveřejňovat je bez předchozího písemného souhlasu uživatele a neprodávat, nevyměňovat, nepublikovat ani jinak zveřejňovat osobní údaje uživatele.\n
               - 4.2.3. Přijímat opatření k ochraně důvěrnosti osobních údajů uživatele podle postupů běžně používaných při ochraně takových informací v běžných obchodních transakcích.\n
               - 4.2.4. Blokovat osobní údaje týkající se příslušného uživatele od okamžiku, kdy uživatel nebo jeho zákonný zástupce nebo oprávněný orgán pro ochranu subjektů osobních údajů podá žádost nebo dotaz, během období ověřování, v případě nepřesných osobních údajů nebo protiprávních činností.\n
               \n
               5. ZODPOVĚDNOST STRAN\n
               - 5.1. Správa webu, která nesplnila své závazky, je zodpovědná za ztráty utrpěné uživatelem v důsledku nezákonného použití osobních údajů, v souladu s právními předpisy Ukrajiny.\n
               - 5.2. V případě ztráty nebo zveřejnění důvěrných informací není správa webu zodpovědná, pokud takové důvěrné informace:\n
               - 5.2.1. Staly se veřejně dostupnými před jejich ztrátou nebo zveřejněním.\n
               - 5.2.2. Byly přijaty od třetí strany předtím, než je získala správa webu.\n
               - 5.2.3. Byly zveřejněny se souhlasem uživatele.\n
               \n
               6. ŘEŠENÍ SPORŮ\n
               - 6.1. Před podáním žaloby s spory vyplývajícími z vztahů mezi uživatelem webu a správou webu je povinné podat reklamaci (písemný návrh na dobrovolné řešení sporu).\n
               - 6.2. Příjemce reklamace musí nahlásit stěžovateli písemně výsledky zvážení reklamace do 30 kalendářních dnů od dne přijetí reklamace.\n
               - 6.3. Pokud nedojde k dohodě, spor bude předán soudnímu orgánu v souladu s platnou legislativou Ukrajiny.\n
               \n
               7. DODATEČNÉ PODMÍNKY\n
               - 7.1. Správa webu má právo provést změny v těchto Zásadách ochrany osobních údajů bez souhlasu uživatele.\n
               - 7.2. Nové Zásady ochrany osobních údajů nabývají účinnosti okamžikem jejich zveřejnění na e-commerce webu, pokud není v nové verzi Politiky stanoveno jinak.`,
    },
    ru: {
        info_text_1:
            'Alberto Bini - это украинский бренд-производитель женской одежды, который уже более 10 лет создает качественную женскую одежду. Производство использует последние материалы и технологии, что позволяет создавать современную, качественную и практичную одежду, которая ценится и востребована по всему миру.',
        info_text_2: `1. ОПЛАТА:\n
               После оформления заказа оплата производится путем перевода средств на указанный банковский счет.
               Платежи принимаются на банковский счет компании Alberto Bini.\n\n
               2. ДОСТАВКА:\n
               - ДЛЯ РОЗНИЧНЫХ ЗАКАЗОВ:\n
               Доставка осуществляется от 2 до 15 рабочих дней.\n
               - ДЛЯ ОПТОВЫХ ЗАКАЗОВ:\n
               Время доставки зависит от объема заказа, обычно в течение 1 месяца.\n\n
               После оформления заказа с вами свяжется менеджер для предоставления более подробной информации о методах оплаты, реквизитах банковского счета и условиях доставки.`,
        info_text_3: `ВОЗВРАТЫ:\n
               - Мы принимаем возвраты в течение 30 дней с даты покупки.
               - Товар должен быть не использован, в оригинальной упаковке и с доказательством покупки.
               - Расходы на обратную доставку несет покупатель, если возврат не связан с дефектом товара или ошибкой с нашей стороны.\n
               ОБМЕН:\n
               - Обмен допускается в течение 30 дней с даты покупки.
               - Товары, допустимые к обмену, должны быть не использованы и в оригинальной упаковке.
               - Расходы на доставку обмена несет покупатель, если обмен не связан с дефектом товара или нашей ошибкой.\n
               ВОЗВРАТ СРЕДСТВ:\n
               - Возврат денежных средств осуществляется на первоначальную форму оплаты в течение 7-10 рабочих дней после получения и проверки возвращенного товара.
               - Стоимость доставки не возвращается, если возврат не связан с дефектом товара или нашей ошибкой.
               - Обратите внимание, что некоторые товары могут подлежать дополнительным ограничениям на возврат. Для дополнительной помощи или начала процесса возврата или обмена, пожалуйста, свяжитесь с нашей службой поддержки клиентов.`,
        info_text_4: `1. ОБЩИЕ ПОЛОЖЕНИЯ\n
               - 1.1. Использование сайта пользователем означает согласие с этой Политикой конфиденциальности и условиями обработки персональных данных пользователя.\n
               - 1.2. В случае несогласия с условиями Политики конфиденциальности пользователь должен прекратить использование сайта.\n
               - 1.3. Эта Политика конфиденциальности применяется только к этому сайту. Администрация сайта не контролирует и не несет ответственности за сторонние сайты, на которые пользователь может перейти по ссылкам, доступным на сайте.\n
               - 1.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых пользователем.\n
               \n
               2. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ\n
               - 2.1. Эта Политика конфиденциальности устанавливает обязательства администрации сайта по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые пользователь предоставляет по запросу администрации сайта.\n
               - 2.2. Персональные данные, разрешенные к обработке в рамках этой Политики конфиденциальности, предоставляются пользователем путем заполнения регистрационной формы на сайте.\n
               \n
               3. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ\n
               - 3.1. Администрация сайта может использовать персональные данные пользователя в целях:\n
               - 3.1.1. Идентификации пользователя для обработки заказа.\n
               - 3.1.2. Предоставления пользователю доступа к персонализированным ресурсам сайта.\n
               - 3.1.3. Установления контакта с пользователем, включая отправку уведомлений, запросов, касающихся использования сайта, предоставления услуг, а также обработки запросов и заявок пользователя.\n
               - 3.1.4. Определения местоположения пользователя для обеспечения безопасности и предотвращения мошенничества.\n
               - 3.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных пользователем.\n
               - 3.1.6. Информирования пользователя о состоянии заказа.\n
               - 3.1.7. Предоставления эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием сайта.\n
               - 3.1.8. Предоставления пользователю, с его согласия, обновлений о продуктах, специальных предложениях, информации о ценах, новостных рассылках и другой информации от имени сайта или его партнеров.\n
               - 3.1.9. Проведения рекламных мероприятий с согласия пользователя.\n
               - 3.1.10. Предоставления пользователю доступа к сайтам или услугам партнеров для получения продуктов, обновлений и услуг.\n
               \n
               4. ОБЯЗАННОСТИ СТОРОН\n
               - 4.1. Пользователь обязан:\n
               - 4.1.1. Предоставить персональные данные, необходимые для использования сайта.\n
               - 4.1.2. Обновлять и дополнять предоставленные персональные данные в случае изменений.\n
               - 4.2. Администрация сайта обязана:\n
               - 4.2.1. Использовать информацию, полученную исключительно для целей, указанных в пункте 3 этой Политики конфиденциальности.\n
               - 4.2.2. Сохранять в тайне конфиденциальную информацию, не разглашать ее без предварительного письменного согласия пользователя и не продавать, не обменивать, не публиковать, а также не раскрывать персональные данные пользователя иным способом.\n
               - 4.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных пользователя в соответствии с порядком, обычно используемым для защиты такой информации в существующих деловых операциях.\n
               - 4.2.4. Блокировать персональные данные, относящиеся к соответствующему пользователю, с момента обращения пользователя или его законного представителя или уполномоченного органа по защите прав субъектов персональных данных, в период проверки, в случае выявления неточных данных или неправомерных действий.\n
               \n
               5. ОТВЕТСТВЕННОСТЬ СТОРОН\n
               - 5.1. Администрация сайта, не выполнив свои обязательства, несет ответственность за убытки, понесенные пользователем в результате незаконного использования персональных данных, в соответствии с законодательством Украины.\n
               - 5.2. В случае потери или разглашения Конфиденциальной информации администрация сайта не несет ответственности, если такая конфиденциальная информация:\n
               - 5.2.1. Стала общедоступной до ее потери или разглашения.\n
               - 5.2.2. Была получена от третьей стороны до того, как ее получила администрация сайта.\n
               - 5.2.3. Была разглашена с согласия пользователя.\n
               \n
               6. РАЗРЕШЕНИЕ СПОРОВ\n
               - 6.1. Перед подачей иска по спорам, возникающим из отношений между пользователем сайта и администрацией сайта, обязательно предъявление претензии (письменное предложение о добровольном урегулировании спора).\n
               - 6.2. Получатель претензии должен уведомить заявителя письменно о результатах рассмотрения претензии в течение 30 календарных дней с дня получения претензии.\n
               - 6.3. Если соглашение не достигнуто, спор направляется на рассмотрение в судебный орган в соответствии с действующим законодательством Украины.\n
               \n
               7. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ\n
               - 7.1. Администрация сайта имеет право вносить изменения в эту Политику конфиденциальности без согласия пользователя.\n
               - 7.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на сайте электронной коммерции, если иное не предусмотрено новой редакцией Политики.`,
    },
    kz: {
        info_text_1:
            'Alberto Bini - бұл 10 жылдан астам уақыт бойы сапалы әйелдер киімін шығаратын украин бренді. Өндіріс ең соңғы материалдар мен технологияларды пайдаланады, бұл заманауи, сапалы және тәжірибелі киімдерді жасауға мүмкіндік береді, олар бүкіл әлемде бағаланады және сұранысқа ие.',
        info_text_2: `1. ТӨЛЕМ:\n
             Тапсырыс бергеннен кейін, төлем банктік шотқа аударым арқылы жүзеге асырылады.
             Alberto Bini компаниясының банктік шотына төлемдер қабылданады.\n
             2. ЖЕТКІЗУ:\n
             - БӨЛШЕК САТУ ТАПСЫРЫСТАРЫ ҮШІН:\n
             Жеткізу мерзімі 2-ден 15 жұмыс күніне дейін.\n
             - ОПТОВЫЙ ЗАКАЗДАР ҮШІН:\n
             Жеткізу уақыты тапсырыс көлеміне байланысты, әдетте 1 ай ішінде.\n\n
             Тапсырыс берілгеннен кейін, сізбен байланысқа шығу үшін менеджер жауап береді, ол төлем әдістері, банктік шот реквизиттері және жеткізу шарттары туралы толығырақ ақпарат береді.`,
        info_text_3: `ҚАЙТАРУ:\n
             - Сатып алынған күннен бастап 30 күн ішінде қайтарымдар қабылданады.
             - Заттар пайдаланылмауы керек, олардың түпнұсқа қаптамасында және сатып алу туралы дәлелмен болуы керек.
             - Қайтару жөнелтілім шығындары тауардың ақауы немесе біздің жағымыздағы қате болған жағдайда ғана клиенттің жауапкершілігіне жатпайды.\n
             АУЫСТЫРУ:\n
             - Сатып алынған күннен бастап 30 күн ішінде ауыстыруға рұқсат етіледі.
             - Ауыстыруға рұқсат етілген заттар пайдаланылмауы және түпнұсқа қаптамасында болуы керек.
             - Ауыстыру жөнелтілім шығындарын клиент жабады, егер ауыстыру тауардың ақауы немесе біздің қатемізге байланысты болмаса.\n
             ҚАРАЖАТТАРДЫ ҚАЙТАРУ:\n
             - Қайтарылған зат алынғаннан және тексерілгеннен кейін 7-10 жұмыс күні ішінде бастапқы төлем формасы бойынша қаржы қайтарылады.
             - Жеткізу шығындары тауардың ақауы немесе біздің қатемізге байланысты болған жағдайда ғана қайтарылады.
             - Кейбір заттар қосымша қайтару шектеулеріне жатуы мүмкін екенін ескеріңіз. Қосымша көмек алу немесе қайтару немесе ауыстыруды бастау үшін клиенттерді қолдау қызметімен байланысыңыз.`,
        info_text_4: `1. ЖАЛПЫ ЕРЕЖЕЛЕР\n
             - 1.1. Пайдаланушының сайтты пайдалануы осы Құпиялылық саясатымен және пайдаланушының жеке деректерін өңдеу шарттарымен келісім білдіреді.\n
             - 1.2. Құпиялылық саясаты шарттарымен келіспеген жағдайда пайдаланушы сайтты пайдалануды тоқтатуы тиіс.\n
             - 1.3. Бұл Құпиялылық саясаты тек осы сайтқа қолданылады. Сайт әкімшілігі пайдаланушының сайтта қолжетімді сілтемелер арқылы өтуі мүмкін үшінші тарап сайттарын бақыламайды және олар үшін жауапты емес.\n
             - 1.4. Сайт әкімшілігі пайдаланушы ұсынған жеке деректердің дәлдігін тексермейді.\n
             \n
             2. ҚҰПИЯЛЫЛЫҚ САЯСАТЫНЫҢ ПӘНІ\n
             - 2.1. Бұл Құпиялылық саясаты сайт әкімшілігінің пайдаланушы ұсынған жеке деректердің құпиялылығын сақтауға және ашпауға міндеттемелерін айқындайды.\n
             - 2.2. Осы Құпиялылық саясаты бойынша өңдеуге рұқсат етілген жеке деректер пайдаланушы сайттағы тіркеу формасын толтыру арқылы беріледі.\n
             \n
             3. ПАЙДАЛАНУШЫНЫҢ ЖЕКЕ АҚПАРАТЫН ЖИНАУ МАҚСАТТАРЫ\n
             - 3.1. Сайт әкімшілігі пайдаланушының жеке деректерін келесі мақсаттар үшін пайдалануы мүмкін:\n
             - 3.1.1. Тапсырысты өңдеу үшін пайдаланушыны анықтау.\n
             - 3.1.2. Пайдаланушыға сайт ресурстарына жеке қолжетімділікті қамтамасыз ету.\n
             - 3.1.3. Пайдаланушымен байланыс орнату, оның ішінде сайтты пайдалануға, қызметтер көрсетуге, пайдаланушы сұраныстары мен өтініштерін өңдеуге байланысты хабарламалар жіберу.\n
             - 3.1.4. Қауіпсіздікті қамтамасыз ету және алаяқтықтың алдын алу үшін пайдаланушының орналасқан жерін анықтау.\n
             - 3.1.5. Пайдаланушы ұсынған жеке деректердің дәлдігі мен толықтығын растау.\n
             - 3.1.6. Пайдаланушыны сайттағы тапсырыс күйі туралы хабардар ету.\n
             - 3.1.7. Сайтты пайдалануға байланысты мәселелер туындаған жағдайда тиімді клиенттік және техникалық қолдау көрсету.\n
             - 3.1.8. Пайдаланушының келісімімен сайттың немесе оның серіктестерінің атынан өнімдер, арнайы ұсыныстар, баға ақпараты, жаңалықтар бюллетендері және басқа да ақпараттармен жаңартулар беру.\n
             - 3.1.9. Пайдаланушының келісімімен жарнамалық іс-шаралар өткізу.\n
             - 3.1.10. Пайдаланушыға серіктес сайттарына немесе өнімдер, жаңартулар және қызметтер алу үшін қызметтерге қолжетімділік беру.\n
             \n
             4. ТАРАПТАРДЫҢ МІНДЕТТЕМЕЛЕРІ\n
             - 4.1. Пайдаланушы міндетті:\n
             - 4.1.1. Сайтты пайдалану үшін қажетті жеке деректерді ұсыну.\n
             - 4.1.2. Кез келген өзгерістер туындаған жағдайда ұсынылған жеке деректер ақпаратын жаңарту және толықтыру.\n
             - 4.2. Сайт әкімшілігі міндетті:\n
             - 4.2.1. Осы Құпиялылық саясатының 3-тармағында көрсетілген мақсаттар үшін алынған ақпаратты пайдалану.\n
             - 4.2.2. Конфиденциалды ақпаратты құпия сақтау, оны пайдаланушының алдын ала жазбаша келісімінсіз ашпау және сатпау, айырбастамау, жарияламау немесе басқа да құралдар арқылы пайдаланушының жеке деректерін ашпау.\n
             - 4.2.3. Бар бизнес операцияларда қолданылатын әдістеме бойынша пайдаланушының жеке деректерінің құпиялығын қорғау үшін қауіпсіздік шараларын қолдану.\n
             - 4.2.4. Пайдаланушының немесе оның заңды өкілінің немесе жеке деректер субъектілерін қорғау бойынша уәкілетті органның сұрауы немесе сұрауынан бастап, тексеру кезеңінде, жеке деректердің дұрыс еместігі немесе заңсыз әрекеттер жағдайында тиісті пайдаланушыға қатысты жеке деректерді блоктау.\n
             \n
             5. ТАРАПТАРДЫҢ ЖАУАПКЕРШІЛІГІ\n
             - 5.1. Өз міндеттемелерін орындамаған сайт әкімшілігі, пайдаланушының жеке деректерін заңсыз пайдаланудан келтірілген шығындар үшін Украина заңдарына сәйкес жауапты.\n
             - 5.2. Құпия ақпараттың жоғалуы немесе ашылуы жағдайында сайт әкімшілігі жауапты емес, егер мұндай құпия ақпарат:\n
             - 5.2.1. Жоғалуы немесе ашылуына дейін жалпыға қолжетімді болған.\n
             - 5.2.2. Оны сайт әкімшілігі алмас бұрын үшінші тараптан алынған.\n
             - 5.2.3. Пайдаланушының келісімімен ашылған.\n
             \n
             6. ДАУЛАРДЫ ШЕШУ\n
             - 6.1. Сайт пайдаланушысы мен сайт әкімшілігі арасындағы қатынастардан туындаған даулар бойынша шағым түсіру алдында дауды ерікті түрде реттеу үшін шағым (жазбаша ұсыныс) беру міндетті.\n
             - 6.2. Шағым алушы шағымды қарау нәтижелері туралы шағымды алған күннен бастап 30 күнтізбелік күн ішінде шағым берушіге жазбаша хабарлауы тиіс.\n
             - 6.3. Келісімге қол жеткізілмеген жағдайда, дау Украина қолданыстағы заңнамасына сәйкес сот органына жолданады.\n
             \n
             7. ҚОСЫМША ШАРТТАР\n
             - 7.1. Сайт әкімшілігі пайдаланушының келісімінсіз осы Құпиялылық саясатына өзгерістер енгізуге құқылы.\n
             - 7.2. Жаңа Құпиялылық саясаты оны электрондық коммерция сайтында жариялаған сәттен бастап күшіне енеді, егер жаңа редакцияда басқаша көзделмесе.`,
    },
};
