export const HOME_PAGE = {
    en: {
        customer_title_1: 'Factory with a History',
        customer_title_2: 'Wholesale Sales',
        customer_title_3: 'Retail Sales',
        customer_title_5: 'Quality Guarantee',
        customer_title_4: 'Extensive Collection',
        customer_description_1:
            'Our factory, with over 10 years of history, is renowned for its quality and reliability. We sell our products worldwide, constantly striving for perfection to ensure every customer is satisfied. Trust our experience and traditions!',
        customer_description_2:
            'We offer favorable terms for wholesale buyers. Our assortment is ideal for large retail chains and stores. Become our partner and receive special offers!',
        customer_description_3:
            'We offer convenient terms for retail customers. A wide selection of fashionable clothing is available to everyone. Shop with us and enjoy quality and style!',
        customer_description_4:
            'Our portfolio includes a wide range of styles for all ages and preferences. We regularly update our assortment to reflect the latest fashion trends. Find your perfect look with us!',
        customer_description_5:
            'We guarantee the high quality of each item, using only durable and fashionable materials. Your purchase will look elegant and last a long time. Choose quality!',
    },
    fr: {
        customer_title_1: `Usine avec une Histoire`,
        customer_title_2: `Ventes en Gros`,
        customer_title_3: `Ventes au Détail`,
        customer_title_5: `Garantie de Qualité`,
        customer_title_4: `Collection Étendue`,
        customer_description_1: `Notre usine, avec plus de 10 ans d'histoire, est réputée pour sa qualité et sa fiabilité. Nous vendons nos produits dans le monde entier, nous efforçant constamment d'atteindre la perfection pour garantir la satisfaction de chaque client. Faites confiance à notre expérience et à nos traditions!`,
        customer_description_2: `Nous offrons des conditions avantageuses pour les acheteurs en gros. Notre assortiment est idéal pour les grandes chaînes de magasins. Devenez notre partenaire et bénéficiez d'offres spéciales!`,
        customer_description_3: `Nous offrons des conditions pratiques pour les clients au détail. Un large choix de vêtements à la mode est disponible pour tous. Faites vos achats chez nous et profitez de la qualité et du style!`,
        customer_description_4: `Notre portefeuille comprend une large gamme de styles pour tous les âges et toutes les préférences. Nous mettons régulièrement à jour notre assortiment pour refléter les dernières tendances de la mode. Trouvez votre look parfait chez nous!`,
        customer_description_5: `Nous garantissons la haute qualité de chaque article, en utilisant uniquement des matériaux durables et à la mode. Votre achat aura une apparence élégante et durera longtemps. Choisissez la qualité!`,
    },
    es: {
        customer_title_1: `Fábrica con Historia`,
        customer_title_2: `Ventas al por Mayor`,
        customer_title_3: `Ventas al por Menor`,
        customer_title_5: `Garantía de Calidad`,
        customer_title_4: `Colección Extensa`,
        customer_description_1: `Nuestra fábrica, con más de 10 años de historia, es reconocida por su calidad y fiabilidad. Vendemos nuestros productos en todo el mundo, esforzándonos constantemente por la perfección para asegurar la satisfacción de cada cliente. ¡Confíe en nuestra experiencia y tradiciones!`,
        customer_description_2: `Ofrecemos condiciones favorables para los compradores al por mayor. Nuestro surtido es ideal para grandes cadenas de tiendas. ¡Conviértase en nuestro socio y reciba ofertas especiales!`,
        customer_description_3: `Ofrecemos condiciones convenientes para los clientes minoristas. Una amplia selección de ropa de moda está disponible para todos. ¡Compre con nosotros y disfrute de la calidad y el estilo!`,
        customer_description_4: `Nuestro portafolio incluye una amplia gama de estilos para todas las edades y preferencias. Actualizamos regularmente nuestro surtido para reflejar las últimas tendencias de la moda. ¡Encuentra tu look perfecto con nosotros!`,
        customer_description_5: `Garantizamos la alta calidad de cada artículo, utilizando solo materiales duraderos y de moda. Su compra se verá elegante y durará mucho tiempo. ¡Elija calidad!`,
    },
    ua: {
        customer_title_1: `Фабрика з Історією`,
        customer_title_2: `Оптовий Продаж`,
        customer_title_3: `Роздрібний Продаж`,
        customer_title_5: `Гарантія Якості`,
        customer_title_4: `Обширна Колекція`,
        customer_description_1: `Наша фабрика з більш ніж 10-річною історією славиться якістю та надійністю. Ми продаємо нашу продукцію по всьому світу, постійно прагнучи до вдосконалення, щоб кожен клієнт був задоволений. Довіртеся нашому досвіду та традиціям!`,
        customer_description_2: `Ми пропонуємо вигідні умови для оптових покупців. Наш асортимент ідеально підходить для великих роздрібних мереж та магазинів. Ставайте нашим партнером і отримуйте спеціальні пропозиції!`,
        customer_description_3: `Ми пропонуємо зручні умови для роздрібних клієнтів. Широкий вибір модного одягу доступний кожному. Робіть покупки у нас і насолоджуйтесь якістю та стилем!`,
        customer_description_4: `Наш портфель включає широкий асортимент стилів для всіх вікових груп і вподобань. Ми регулярно оновлюємо наш асортимент з урахуванням останніх модних тенденцій. Знайдіть свій ідеальний образ у нас!`,
        customer_description_5: `Ми гарантуємо високу якість кожної речі, використовуючи лише міцні та модні матеріали. Ваша покупка виглядатиме елегантно та служитиме довго. Обирайте якість!`,
    },
    pl: {
        customer_title_1: `Fabryka z Historią`,
        customer_title_2: `Sprzedaż Hurtowa`,
        customer_title_3: `Sprzedaż Detaliczna`,
        customer_title_5: `Gwarancja Jakości`,
        customer_title_4: `Rozległa Kolekcja`,
        customer_description_1: `Nasza fabryka z ponad 10-letnią historią słynie z jakości i niezawodności. Sprzedajemy nasze produkty na całym świecie, nieustannie dążąc do doskonałości, aby każdy klient był zadowolony. Zaufaj naszemu doświadczeniu i tradycjom!`,
        customer_description_2: `Oferujemy korzystne warunki dla hurtowych kupców. Nasz asortyment jest idealny dla dużych sieci detalicznych i sklepów. Zostań naszym partnerem i otrzymaj specjalne oferty!`,
        customer_description_3: `Oferujemy dogodne warunki dla klientów detalicznych. Szeroki wybór modnej odzieży jest dostępny dla każdego. Kupuj u nas i ciesz się jakością i stylem!`,
        customer_description_4: `Nasz portfolio obejmuje szeroką gamę stylów dla wszystkich grup wiekowych i preferencji. Regularnie aktualizujemy nasz asortyment, aby odzwierciedlał najnowsze trendy mody. Znajdź swój idealny wygląd u nas!`,
        customer_description_5: `Gwarantujemy wysoką jakość każdego przedmiotu, używając tylko trwałych i modnych materiałów. Twój zakup będzie wyglądał elegancko i długo służył. Wybierz jakość!`,
    },
    cz: {
        customer_title_1: `Továrna s Historií`,
        customer_title_2: `Velkoobchodní Prodej`,
        customer_title_3: `Maloobchodní Prodej`,
        customer_title_5: `Záruka Kvality`,
        customer_title_4: `Široká Kolekce`,
        customer_description_1: `Naše továrna s více než 10letou historií je známá svou kvalitou a spolehlivostí. Prodáváme naše produkty po celém světě, neustále usilujeme o dokonalost, aby byl každý zákazník spokojen. Důvěřujte našim zkušenostem a tradicím!`,
        customer_description_2: `Nabízíme výhodné podmínky pro velkoobchodní kupce. Náš sortiment je ideální pro velké maloobchodní řetězce a obchody. Staňte se naším partnerem a získejte speciální nabídky!`,
        customer_description_3: `Nabízíme pohodlné podmínky pro maloobchodní zákazníky. Široký výběr módního oblečení je k dispozici všem. Nakupujte u nás a užijte si kvalitu a styl!`,
        customer_description_4: `Náš portfolio zahrnuje širokou škálu stylů pro všechny věkové skupiny a preference. Pravidelně aktualizujeme náš sortiment, aby odrážel nejnovější módní trendy. Najděte svůj dokonalý vzhled u nás!`,
        customer_description_5: `Garantujeme vysokou kvalitu každého předmětu, používáme pouze trvanlivé a módní materiály. Váš nákup bude vypadat elegantně a dlouho vydrží. Vyberte si kvalitu!`,
    },
    ru: {
        customer_title_1: `Фабрика с Историей`,
        customer_title_2: `Оптовая Продажа`,
        customer_title_3: `Розничная Продажа`,
        customer_title_5: `Гарантия Качества`,
        customer_title_4: `Обширная Коллекция`,
        customer_description_1: `Наша фабрика с более чем 10-летней историей славится качеством и надежностью. Мы продаем нашу продукцию по всему миру, стремясь к постоянному совершенствованию, чтобы каждый клиент был доволен. Доверьтесь нашему опыту и традициям!`,
        customer_description_2: `Мы предлагаем выгодные условия для оптовых покупателей. Наш ассортимент идеально подходит для крупных розничных сетей и магазинов. Станьте нашим партнером и получите специальные предложения!`,
        customer_description_3: `Мы предлагаем удобные условия для розничных клиентов. Широкий выбор модной одежды доступен каждому. Делайте покупки у нас и наслаждайтесь качеством и стилем!`,
        customer_description_4: `Наш портфель включает широкий ассортимент стилей для всех возрастов и предпочтений. Регулярно обновляем наш ассортимент с учетом последних модных тенденций. Найдите свой идеальный образ у нас!`,
        customer_description_5: `Мы гарантируем высокое качество каждой вещи, используя только прочные и модные материалы. Ваша покупка будет выглядеть элегантно и служить долго. Сделайте выбор в пользу качества!`,
    },
    kz: {
        customer_title_1: `Фабрика Тарихы`,
        customer_title_2: `Көтерме Сауда`,
        customer_title_3: `Бөлшек Сауда`,
        customer_title_5: `Сапа Кепілдігі`,
        customer_title_4: `Кең Коллекция`,
        customer_description_1: `Біздің фабрикамыз 10 жылдан астам тарихымен сапа мен сенімділікпен танымал. Біз өз өнімдерімізді бүкіл әлемге сатамыз, әрбір клиенттің қанағаттануы үшін үнемі жетілдіруге тырысамыз. Тәжірибемізге және дәстүрлерімізге сеніңіз!`,
        customer_description_2: `Біз көтерме сатып алушылар үшін қолайлы шарттарды ұсынамыз. Біздің ассортиментіміз ірі бөлшек сауда желілері мен дүкендерге мінсіз сай келеді. Біздің серіктесіміз болыңыз және арнайы ұсыныстар алыңыз!`,
        customer_description_3: `Біз бөлшек сауда клиенттері үшін ыңғайлы шарттарды ұсынамыз. Сәнді киімдердің кең таңдауы барлығына қолжетімді. Біздің қолымыздан сауда жасаңыз және сапа мен стильден ләззат алыңыз!`,
        customer_description_4: `Біздің портфолиоымыз барлық жас топтары мен талғамдарға арналған стильдердің кең ауқымын қамтиды. Біз өз ассортиментімізді соңғы сән трендтеріне сәйкес үнемі жаңартып отырамыз. Біздің қолымыздан сіздің мінсіз көрінісіңізді табыңыз!`,
        customer_description_5: `Біз әрбір бұйымның жоғары сапасына кепілдік береміз, тек берік және сәнді материалдарды қолданамыз. Сіздің сатып алуыңыз әдемі көрінеді және ұзақ уақытқа қызмет етеді. Сапаны таңдаңыз!`,
    },
};
