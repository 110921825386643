export const SUPPORTED_APP_LANG = {
    en: {
        polish: 'Polish',
        czech: 'Czech',
        russian: 'Russian',
        ukrainian: 'Ukrainian',
        spanish: 'Spanish',
        french: 'French',
        english: 'English',
        kazakh: 'Kazakh',
        italian: 'Italian',
        turkish: 'Turkish',
        german: 'German',
        lang_unknown: '...',
    },
    fr: {
        polish: 'Polonais',
        czech: 'Tchèque',
        russian: 'Russe',
        ukrainian: 'Ukrainien',
        spanish: 'Espagnol',
        french: 'Français',
        english: 'Anglais',
        kazakh: 'Kazakh',
        italian: 'Italien',
        turkish: 'Turc',
        german: 'Allemand',
        lang_unknown: '...',
    },
    es: {
        polish: 'Polaco',
        czech: 'Checo',
        russian: 'Ruso',
        ukrainian: 'Ucraniano',
        spanish: 'Español',
        french: 'Francés',
        english: 'Inglés',
        kazakh: 'Kazajo',
        italian: 'Italiano',
        turkish: 'Turco',
        german: 'Alemán',
        lang_unknown: '...',
    },
    ua: {
        polish: 'Польська',
        czech: 'Чеська',
        russian: 'Російська',
        ukrainian: 'Українська',
        spanish: 'Іспанська',
        french: 'Французька',
        english: 'Англійська',
        kazakh: 'Казахська',
        italian: 'Італійська',
        turkish: 'Турецька',
        german: 'Німецька',
        lang_unknown: '...',
    },
    pl: {
        polish: 'Polski',
        czech: 'Czeski',
        russian: 'Rosyjski',
        ukrainian: 'Ukraiński',
        spanish: 'Hiszpański',
        french: 'Francuski',
        english: 'Angielski',
        kazakh: 'Kazachski',
        italian: 'Włoski',
        turkish: 'Turecki',
        german: 'Niemiecki',
        lang_unknown: '...',
    },
    cz: {
        polish: 'Polština',
        czech: 'Čeština',
        russian: 'Ruština',
        ukrainian: 'Ukrajinština',
        spanish: 'Španělština',
        french: 'Francouzština',
        english: 'Angličtina',
        kazakh: 'Kazachština',
        italian: 'Italština',
        turkish: 'Turečtina',
        german: 'Němčina',
        lang_unknown: '...',
    },
    ru: {
        polish: 'Польский',
        czech: 'Чешский',
        russian: 'Русский',
        ukrainian: 'Украинский',
        spanish: 'Испанский',
        french: 'Французский',
        english: 'Английский',
        kazakh: 'Казахский',
        italian: 'Итальянский',
        turkish: 'Турецкий',
        german: 'Немецкий',
        lang_unknown: '...',
    },
    kz: {
        polish: 'Поляк тілі',
        czech: 'Чех тілі',
        russian: 'Орыс тілі',
        ukrainian: 'Украин тілі',
        spanish: 'Испан тілі',
        french: 'Француз тілі',
        english: 'Ағылшын тілі',
        kazakh: 'Қазақ тілі',
        italian: 'Итальян тілі',
        turkish: 'Түрік тілі',
        german: 'Неміс тілі',
        lang_unknown: '...',
    },
};
