export const COUNTRIES = {
    en: {
        ukraine: 'Ukraine',
        czechia: 'Czechia',
        italy: 'Italy',
        kazakhstan: 'Kazakhstan',
        poland: 'Poland',
        turkey: 'Turkey',
        albania: 'Albania',
        andorra: 'Andorra',
        armenia: 'Armenia',
        austria: 'Austria',
        azerbaijan: 'Azerbaijan',
        belarus: 'Belarus',
        belgium: 'Belgium',
        bosnia_and_herzegovina: 'Bosnia and Herzegovina',
        bulgaria: 'Bulgaria',
        croatia: 'Croatia',
        cyprus: 'Cyprus',
        denmark: 'Denmark',
        estonia: 'Estonia',
        faroe_islands: 'Faroe Islands',
        finland: 'Finland',
        france: 'France',
        georgia: 'Georgia',
        germany: 'Germany',
        gibraltar: 'Gibraltar',
        greece: 'Greece',
        guernsey: 'Guernsey',
        hungary: 'Hungary',
        iceland: 'Iceland',
        ireland: 'Ireland',
        isle_of_man: 'Isle of Man',
        jersey: 'Jersey',
        kosovo: 'Kosovo',
        latvia: 'Latvia',
        liechtenstein: 'Liechtenstein',
        lithuania: 'Lithuania',
        luxembourg: 'Luxembourg',
        malta: 'Malta',
        moldova: 'Moldova',
        monaco: 'Monaco',
        montenegro: 'Montenegro',
        netherlands: 'Netherlands',
        north_macedonia: 'North Macedonia',
        norway: 'Norway',
        portugal: 'Portugal',
        romania: 'Romania',
        russia: 'Russia',
        san_marino: 'San Marino',
        serbia: 'Serbia',
        slovakia: 'Slovakia',
        slovenia: 'Slovenia',
        spain: 'Spain',
        svalbard_and_jan_mayen: 'Svalbard and Jan Mayen',
        sweden: 'Sweden',
        united_kingdom: 'United Kingdom',
        vatican_city: 'Vatican City',
    },
    fr: {
        ukraine: 'Ukraine',
        czechia: 'République tchèque',
        italy: 'Italie',
        kazakhstan: 'Kazakhstan',
        poland: 'Pologne',
        turkey: 'Turquie',
        albania: 'Albanie',
        andorra: 'Andorre',
        armenia: 'Arménie',
        austria: 'Autriche',
        azerbaijan: 'Azerbaïdjan',
        belarus: 'Biélorussie',
        belgium: 'Belgique',
        bosnia_and_herzegovina: 'Bosnie-Herzégovine',
        bulgaria: 'Bulgarie',
        croatia: 'Croatie',
        cyprus: 'Chypre',
        denmark: 'Danemark',
        estonia: 'Estonie',
        faroe_islands: 'Îles Féroé',
        finland: 'Finlande',
        france: 'France',
        georgia: 'Géorgie',
        germany: 'Allemagne',
        gibraltar: 'Gibraltar',
        greece: 'Grèce',
        guernsey: 'Guernesey',
        hungary: 'Hongrie',
        iceland: 'Islande',
        ireland: 'Irlande',
        isle_of_man: 'Île de Man',
        jersey: 'Jersey',
        kosovo: 'Kosovo',
        latvia: 'Lettonie',
        liechtenstein: 'Liechtenstein',
        lithuania: 'Lituanie',
        luxembourg: 'Luxembourg',
        malta: 'Malte',
        moldova: 'Moldavie',
        monaco: 'Monaco',
        montenegro: 'Monténégro',
        netherlands: 'Pays-Bas',
        north_macedonia: 'Macédoine du Nord',
        norway: 'Norvège',
        portugal: 'Portugal',
        romania: 'Roumanie',
        russia: 'Russie',
        san_marino: 'Saint-Marin',
        serbia: 'Serbie',
        slovakia: 'Slovaquie',
        slovenia: 'Slovénie',
        spain: 'Espagne',
        svalbard_and_jan_mayen: 'Svalbard et Jan Mayen',
        sweden: 'Suède',
        united_kingdom: 'Royaume-Uni',
        vatican_city: 'Vatican',
    },
    es: {
        ukraine: 'Ucrania',
        czechia: 'República Checa',
        italy: 'Italia',
        kazakhstan: 'Kazajistán',
        poland: 'Polonia',
        turkey: 'Turquía',
        albania: 'Albania',
        andorra: 'Andorra',
        armenia: 'Armenia',
        austria: 'Austria',
        azerbaijan: 'Azerbaiyán',
        belarus: 'Bielorrusia',
        belgium: 'Bélgica',
        bosnia_and_herzegovina: 'Bosnia-Herzegovina',
        bulgaria: 'Bulgaria',
        croatia: 'Croacia',
        cyprus: 'Chipre',
        denmark: 'Dinamarca',
        estonia: 'Estonia',
        faroe_islands: 'Islas Feroe',
        finland: 'Finlandia',
        france: 'Francia',
        georgia: 'Georgia',
        germany: 'Alemania',
        gibraltar: 'Gibraltar',
        greece: 'Grecia',
        guernsey: 'Guernesey',
        hungary: 'Hungría',
        iceland: 'Islandia',
        ireland: 'Irlanda',
        isle_of_man: 'Isla de Man',
        jersey: 'Jersey',
        kosovo: 'Kosovo',
        latvia: 'Letonia',
        liechtenstein: 'Liechtenstein',
        lithuania: 'Lituania',
        luxembourg: 'Luxemburgo',
        malta: 'Malta',
        moldova: 'Moldavia',
        monaco: 'Mónaco',
        montenegro: 'Montenegro',
        netherlands: 'Países Bajos',
        north_macedonia: 'Macedonia del Norte',
        norway: 'Noruega',
        portugal: 'Portugal',
        romania: 'Rumanía',
        russia: 'Rusia',
        san_marino: 'San Marino',
        serbia: 'Serbia',
        slovakia: 'Eslovaquia',
        slovenia: 'Eslovenia',
        spain: 'España',
        svalbard_and_jan_mayen: 'Svalbard e Islas Jan Mayen',
        sweden: 'Suecia',
        united_kingdom: 'Reino Unido',
        vatican_city: 'Ciudad del Vaticano',
    },
    ua: {
        ukraine: 'Україна',
        czechia: 'Чехія',
        italy: 'Італія',
        kazakhstan: 'Казахстан',
        poland: 'Польща',
        turkey: 'Туреччина',
        albania: 'Албанія',
        andorra: 'Андорра',
        armenia: 'Вірменія',
        austria: 'Австрія',
        azerbaijan: 'Азербайджан',
        belarus: 'Білорусь',
        belgium: 'Бельгія',
        bosnia_and_herzegovina: 'Боснія і Герцеговина',
        bulgaria: 'Болгарія',
        croatia: 'Хорватія',
        cyprus: 'Кіпр',
        denmark: 'Данія',
        estonia: 'Естонія',
        faroe_islands: 'Фарерські острови',
        finland: 'Фінляндія',
        france: 'Франція',
        georgia: 'Грузія',
        germany: 'Німеччина',
        gibraltar: 'Гібралтар',
        greece: 'Греція',
        guernsey: 'Гернсі',
        hungary: 'Угорщина',
        iceland: 'Ісландія',
        ireland: 'Ірландія',
        isle_of_man: 'Острів Мен',
        jersey: 'Джерсі',
        kosovo: 'Косово',
        latvia: 'Латвія',
        liechtenstein: 'Ліхтенштейн',
        lithuania: 'Литва',
        luxembourg: 'Люксембург',
        malta: 'Мальта',
        moldova: 'Молдова',
        monaco: 'Монако',
        montenegro: 'Чорногорія',
        netherlands: 'Нідерланди',
        north_macedonia: 'Північна Македонія',
        norway: 'Норвегія',
        portugal: 'Португалія',
        romania: 'Румунія',
        russia: 'Росія',
        san_marino: 'Сан-Марино',
        serbia: 'Сербія',
        slovakia: 'Словаччина',
        slovenia: 'Словенія',
        spain: 'Іспанія',
        svalbard_and_jan_mayen: 'Шпіцберген та Ян-Маєн',
        sweden: 'Швеція',
        united_kingdom: 'Сполучене Королівство',
        vatican_city: 'Ватикан',
    },

    pl: {
        ukraine: 'Ukraina',
        czechia: 'Czechy',
        italy: 'Włochy',
        kazakhstan: 'Kazachstan',
        poland: 'Polska',
        turkey: 'Turcja',
        albania: 'Albania',
        andorra: 'Andora',
        armenia: 'Armenia',
        austria: 'Austria',
        azerbaijan: 'Azerbejdżan',
        belarus: 'Białoruś',
        belgium: 'Belgia',
        bosnia_and_herzegovina: 'Bośnia i Hercegowina',
        bulgaria: 'Bułgaria',
        croatia: 'Chorwacja',
        cyprus: 'Cypr',
        denmark: 'Dania',
        estonia: 'Estonia',
        faroe_islands: 'Wyspy Owcze',
        finland: 'Finlandia',
        france: 'Francja',
        georgia: 'Gruzja',
        germany: 'Niemcy',
        gibraltar: 'Gibraltar',
        greece: 'Grecja',
        guernsey: 'Guernsey',
        hungary: 'Węgry',
        iceland: 'Islandia',
        ireland: 'Irlandia',
        isle_of_man: 'Wyspa Man',
        jersey: 'Jersey',
        kosovo: 'Kosowo',
        latvia: 'Łotwa',
        liechtenstein: 'Liechtenstein',
        lithuania: 'Litwa',
        luxembourg: 'Luksemburg',
        malta: 'Malta',
        moldova: 'Mołdawia',
        monaco: 'Monako',
        montenegro: 'Czarnogóra',
        netherlands: 'Holandia',
        north_macedonia: 'Macedonia Północna',
        norway: 'Norwegia',
        portugal: 'Portugalia',
        romania: 'Rumunia',
        russia: 'Rosja',
        san_marino: 'San Marino',
        serbia: 'Serbia',
        slovakia: 'Słowacja',
        slovenia: 'Słowenia',
        spain: 'Hiszpania',
        svalbard_and_jan_mayen: 'Svalbard i Jan Mayen',
        sweden: 'Szwecja',
        united_kingdom: 'Wielka Brytania',
        vatican_city: 'Watykan',
    },
    cz: {
        ukraine: 'Ukrajina',
        czechia: 'Česko',
        italy: 'Itálie',
        kazakhstan: 'Kazachstán',
        poland: 'Polsko',
        turkey: 'Turecko',
        albania: 'Albánie',
        andorra: 'Andorra',
        armenia: 'Arménie',
        austria: 'Rakousko',
        azerbaijan: 'Ázerbájdžán',
        belarus: 'Bělorusko',
        belgium: 'Belgie',
        bosnia_and_herzegovina: 'Bosna a Hercegovina',
        bulgaria: 'Bulharsko',
        croatia: 'Chorvatsko',
        cyprus: 'Kypr',
        denmark: 'Dánsko',
        estonia: 'Estonsko',
        faroe_islands: 'Faerské ostrovy',
        finland: 'Finsko',
        france: 'Francie',
        georgia: 'Gruzie',
        germany: 'Německo',
        gibraltar: 'Gibraltar',
        greece: 'Řecko',
        guernsey: 'Guernsey',
        hungary: 'Maďarsko',
        iceland: 'Island',
        ireland: 'Irsko',
        isle_of_man: 'Man',
        jersey: 'Jersey',
        kosovo: 'Kosovo',
        latvia: 'Lotyšsko',
        liechtenstein: 'Lichtenštejnsko',
        lithuania: 'Litva',
        luxembourg: 'Lucembursko',
        malta: 'Malta',
        moldova: 'Moldavsko',
        monaco: 'Monako',
        montenegro: 'Černá Hora',
        netherlands: 'Nizozemsko',
        north_macedonia: 'Severní Makedonie',
        norway: 'Norsko',
        portugal: 'Portugalsko',
        romania: 'Rumunsko',
        russia: 'Rusko',
        san_marino: 'San Marino',
        serbia: 'Srbsko',
        slovakia: 'Slovensko',
        slovenia: 'Slovinsko',
        spain: 'Španělsko',
        svalbard_and_jan_mayen: 'Špicberky a Jan Mayen',
        sweden: 'Švédsko',
        united_kingdom: 'Spojené království',
        vatican_city: 'Vatikán',
    },
    ru: {
        ukraine: 'Украина',
        czechia: 'Чехия',
        italy: 'Италия',
        kazakhstan: 'Казахстан',
        poland: 'Польша',
        turkey: 'Турция',
        albania: 'Албания',
        andorra: 'Андорра',
        armenia: 'Армения',
        austria: 'Австрия',
        azerbaijan: 'Азербайджан',
        belarus: 'Беларусь',
        belgium: 'Бельгия',
        bosnia_and_herzegovina: 'Босния и Герцеговина',
        bulgaria: 'Болгария',
        croatia: 'Хорватия',
        cyprus: 'Кипр',
        denmark: 'Дания',
        estonia: 'Эстония',
        faroe_islands: 'Фарерские острова',
        finland: 'Финляндия',
        france: 'Франция',
        georgia: 'Грузия',
        germany: 'Германия',
        gibraltar: 'Гибралтар',
        greece: 'Греция',
        guernsey: 'Гернси',
        hungary: 'Венгрия',
        iceland: 'Исландия',
        ireland: 'Ирландия',
        isle_of_man: 'Остров Мэн',
        jersey: 'Джерси',
        kosovo: 'Косово',
        latvia: 'Латвия',
        liechtenstein: 'Лихтенштейн',
        lithuania: 'Литва',
        luxembourg: 'Люксембург',
        malta: 'Мальта',
        moldova: 'Молдова',
        monaco: 'Монако',
        montenegro: 'Черногория',
        netherlands: 'Нидерланды',
        north_macedonia: 'Северная Македония',
        norway: 'Норвегия',
        portugal: 'Португалия',
        romania: 'Румыния',
        russia: 'Россия',
        san_marino: 'Сан-Марино',
        serbia: 'Сербия',
        slovakia: 'Словакия',
        slovenia: 'Словения',
        spain: 'Испания',
        svalbard_and_jan_mayen: 'Шпицберген и Ян-Майен',
        sweden: 'Швеция',
        united_kingdom: 'Соединенное Королевство',
        vatican_city: 'Ватикан',
    },

    kz: {
        ukraine: 'Украина',
        czechia: 'Чехия',
        italy: 'Италия',
        kazakhstan: 'Казахстан',
        poland: 'Польша',
        turkey: 'Түркия',
        albania: 'Албания',
        andorra: 'Андорра',
        armenia: 'Армения',
        austria: 'Австрия',
        azerbaijan: 'Әзербайжан',
        belarus: 'Белоруссия',
        belgium: 'Бельгия',
        bosnia_and_herzegovina: 'Босния және Герцеговина',
        bulgaria: 'Болгария',
        croatia: 'Хорватия',
        cyprus: 'Кипр',
        denmark: 'Дания',
        estonia: 'Эстония',
        faroe_islands: 'Фарер аралдары',
        finland: 'Финляндия',
        france: 'Франция',
        georgia: 'Грузия',
        germany: 'Германия',
        gibraltar: 'Гибралтар',
        greece: 'Греция',
        guernsey: 'Гернси',
        hungary: 'Венгрия',
        iceland: 'Исландия',
        ireland: 'Ирландия',
        isle_of_man: 'Мэн аралы',
        jersey: 'Джерси',
        kosovo: 'Косово',
        latvia: 'Латвия',
        liechtenstein: 'Лихтенштейн',
        lithuania: 'Литва',
        luxembourg: 'Люксембург',
        malta: 'Мальта',
        moldova: 'Молдова',
        monaco: 'Монако',
        montenegro: 'Черногория',
        netherlands: 'Нидерланды',
        north_macedonia: 'Северная Македония',
        norway: 'Норвегия',
        portugal: 'Португалия',
        romania: 'Румыния',
        russia: 'Россия',
        san_marino: 'Сан-Марино',
        serbia: 'Сербия',
        slovakia: 'Словакия',
        slovenia: 'Словения',
        spain: 'Испания',
        svalbard_and_jan_mayen: 'Шпицберген және Ян-Майен',
        sweden: 'Швеция',
        united_kingdom: 'Соединенное Королевство',
        vatican_city: 'Ватикан',
    },
};
