import { useEffect, useState } from 'react';
import { INFORMATION } from 'dataBase/translations/information';
import { HOME_PAGE } from 'dataBase/translations/home_page';
import { COUNTRIES } from 'dataBase/translations/countries';
import { LONG_PHRASES } from 'dataBase/translations/long_phrases';
import { SUPPORTED_APP_LANG } from 'dataBase/translations/supported_app_lang';
import { APP_INFO } from 'dataBase/translations/app_information';
import { ADDITIONAL } from 'dataBase/translations/additional';

interface Props {
    lang: string;
    storeName: string;
}
export interface LanguageDataInterface {
    code: string;
    string: Record<string, any>;
}

export interface LangResInterface {
    currentLanguage: LanguageDataInterface;
}

export const useGetLanguage = ({ lang, storeName }: Props): LangResInterface => {
    const [currentLanguage, setCurrentLanguage] = useState({ code: '', string: {} });

    const langList = [
        {
            code: 'en',
            string: {
                ...SUPPORTED_APP_LANG?.en,
                ...LONG_PHRASES?.en,
                ...INFORMATION?.en,
                ...HOME_PAGE?.en,
                ...COUNTRIES?.en,
                ...APP_INFO?.en,
                ...ADDITIONAL.en,
                click_here_to_ask_for_wholesale_pricing_information:
                    'Click here to ask for wholesale pricing information',
                get_pricing: 'Get pricing',
                pricing_form_description: 'Request Wholesale Pricing and Minimum Order Information',
                want_to_know_all_prices: 'Want to know all catalog prices',
                selected_model: 'Selected model',
                hero_text: `An online product catalog is a software solution that allows creating electronic catalogs with product descriptions. It provides features for easy addition of products, classification, description, adding photos, and other important information. Users can organize products into different categories, set up convenient filters, perform searches to simplify navigation. It is often used by companies to create virtual trade catalogs, visualize preliminary projects, online stores, present products at exhibitions, and presentations.`,
                to: 'to',
                in: 'in',
                no_available_sizes: 'No available sizes',
                image_not_loaded: 'Image not loaded',
                no_categories_available: 'No categories available',
                back_to_shopping: 'Back To Shopping',
                order_sent_successfully: 'Order Sent Successfully',
                deleted_by_owner: 'Deleted By Owner',
                details: 'Details',
                final_price: 'Final Price',
                quantity: 'Quantity',
                size: 'Size',
                processed: 'Processed',
                ordered: 'Ordered',
                delivered: 'Delivered',
                refunded: 'Refunded',
                canceled: 'Canceled',
                order: 'Order',
                ordered_date: 'Order Date',
                personal_data: 'Personal Data',
                delivery_data: 'Delivery Data',
                update: 'Update',
                profile: 'Profile',
                orders: 'Orders',
                clear_favorites: 'Clear favorites',
                not_required: 'Not required',
                excluding_delivery: 'Excluding delivery',
                total: 'total',
                delivery_address: 'Delivery address',
                delivery_information: 'Delivery information',
                confirm_order: 'Confirm order',
                no_sizes_added: 'No sizes added',
                delete: 'Delete',
                select_sizes: 'Select Sizes',
                shown: 'Shown',
                languages: 'Languages',
                back_to_stores: 'Back to stores',
                ok: 'Ok',
                search: 'Search',
                language: 'Language',
                wrong_key: 'Wrong key!',
                approve: 'Approve',
                ready: 'Ready',
                clear_filters: 'Clear filters',
                clear: 'Clear',
                filters: 'Filters',
                nothing_was_found: 'Nothing was found',
                stores: 'Stores',
                catalog: 'Catalog',
                find_store_by_name: 'Find store by name',
                favorites: 'Favorites',
                my_stores: 'My stores',
                login: 'Login',
                register: 'Register',
                forgot_password: 'Forgot password?',
                cart: 'Cart',
                vendor_code: 'Product code',
                price: 'Price',
                logout: 'Logout',
                submit: 'Submit',
                description: 'Description',
                added: 'Added',
                add_to: 'Add To',
                first_name: 'First Name',
                last_name: 'Last Name',
                phone_number: 'Phone Number',
                city: 'City',
                email: 'Email',
                password: 'Password',
                confirm_password: 'Confirm password',
                company_name: 'Company Name',
                already_registered: 'Already registered',
                cancel: 'Cancel',
                clear_cart: 'Clear cart',
                do_you_want_to_clear_cart: 'Do you want to clear cart?',
                enter_password: 'Enter password',
                enter_email: 'Enter email',
                enter_valid_email: 'Enter valid email',
                password_length_min_4_symbols: 'Password length min 4 symbols',
                passwords_do_not_match: 'Passwords do not match',
                enter_first_name: 'Enter first name',
                enter_last_name: 'Enter last name',
                enter_phone_number: 'Enter phone number',
                my_profile: 'My profile',
                country: 'Country',
                e_a: 'e.a.',
                reset_password: 'Reset Password',
                close: 'Close',
                find_by_vendor_code: 'Find by product code',
                colors: 'Colors',
                color: 'Color',
                sizes: 'Sizes',
                contacts: 'Contacts',
                name: 'Name',
                no_results: 'No results',
                loading: 'Loading',
                phone_length_minimum_7_characters: 'Phone length minimum 7 characters',
                phone_length_max_14_characters: 'Phone length maximum 14 characters',
                size_chart: 'Size chart',
                load_more: 'Load more',
                out_of: 'out of',
                models: 'models',
                in_order_to_use_this_option_you_must_log_in_or_register:
                    'In order to use this option, you must log in or register.',
                you_need_to_login: 'You need to login!',
                sended: 'Sended',
                store_key: 'Store key',
                enter_store_key: 'Enter store key!',
                language_error: 'Language error!',
                support: 'Support',
                ask_question: 'Ask question',
                about_store: 'About Store',
                payments_delivery: 'Payments & Delivery',
                return_exchange: 'Returns & Exchange',
                privacy_policy: 'Privacy Policy',
                info_title_1: `About brand`,
                info_title_2: 'Payments & Delivery',
                info_title_3: 'Returns & Exchange',
                info_title_4: 'Privacy Policy',
                message: 'Message',
                info: 'Info',
                item: 'item(s)',
                add_more: 'Add more',
                retail_catalog: 'Retail',
                wholesale_catalog: 'Wholesale',
                web_mob_view: 'Web and Mobile View',
                examples: 'Examples',
                main_advantages: 'Main Advantages',
                online_catalog_for_your_business: 'Online Catalog for your business',
                first_month_free: 'First month free',
                home: 'Home',
                main_function: `Main Functionality`,
                admin_panel: `Admin Panel`,
                admin: `Administrators`,
                products_models_photo: `Products/Models/Photos`,
                catalog_lang: `Catalog Languages`,
                customization: `Customization`,
                owners: `For Owner`,
                customers: `For Customer`,
                request: `Request`,
                show_all: `Show All`,
                unlimited: `Unlimited`,
                month: `Month`,
                i_want: `I Want to Order`,
                request_catalog: `Request Catalog`,
                consultation: `Request Consultation`,
                request_example: `Request Example`,
                plan: `Plan`,
                comment: `Comment`,
                required_field: 'Required field',
                question: 'Question',
                send: 'Send',
                request_sended_successfully: 'The request has been sent successfully! We will contact you shortly.',
                wrong_password_or_user_not_registered_in:
                    'Incorrect password or user with this email is not registered in',
                do_want_to_logout: 'Are you sure you want to leave your account?',
                enter_new_password: 'Enter new password',
                clear_form: 'Clear form',
                password_changed: 'Password changed',
                add_to_cart: 'Add to cart',
                in_cart: 'In cart',
                menu: 'Menu',
                save_delivery_info: 'Save delivery info',
                available_sizes: 'Available sizes',
                important: 'Important',

                copied_to_clipboard: 'Copied to clipboard',
                security_check: 'Security check',
            },
        },
        {
            code: 'fr',
            string: {
                ...SUPPORTED_APP_LANG?.fr,
                ...LONG_PHRASES?.fr,
                ...INFORMATION?.fr,
                ...HOME_PAGE?.fr,
                ...COUNTRIES?.fr,
                ...APP_INFO?.fr,
                ...ADDITIONAL.fr,
                click_here_to_ask_for_wholesale_pricing_information:
                    'Cliquez ici pour demander des informations sur les prix de gros',
                get_pricing: 'Obtenir les prix',
                pricing_form_description: 'Demander les prix de gros et les informations sur les commandes minimales',
                want_to_know_all_prices: 'Voulez-vous connaître tous les prix du catalogue',
                selected_model: 'Modèle sélectionné',
                to: 'à',
                in: 'dans',
                no_available_sizes: 'Aucune taille disponible',
                image_not_loaded: 'Image non chargée',
                no_categories_available: 'Aucune catégorie disponible',
                back_to_shopping: 'Retour aux achats',
                order_sent_successfully: 'Commande envoyée avec succès',
                deleted_by_owner: 'Supprimé par le propriétaire',
                details: 'Détails',
                final_price: 'Prix final',
                quantity: 'Quantité',
                size: 'Taille',
                processed: 'Traité',
                ordered: 'Commandé',
                delivered: 'Livré',
                refunded: 'Remboursé',
                canceled: 'Annulé',
                order: 'Commander',
                ordered_date: 'Date de commande',
                personal_data: 'Données personnelles',
                delivery_data: 'Données de livraison',
                update: 'Mise à jour',
                profile: 'Profil',
                orders: 'Commandes',
                clear_favorites: 'Effacer les favoris',
                not_required: 'Non requis',
                excluding_delivery: 'Hors livraison',
                total: 'total',
                delivery_address: 'Adresse de livraison',
                delivery_information: 'Informations de livraison',
                confirm_order: 'Confirmer la commande',
                no_sizes_added: 'Aucune taille ajoutée',
                delete: 'Supprimer',
                select_sizes: 'Sélectionner les tailles',
                show: 'ShAffichéown',
                languages: 'Langues',
                back_to_stores: 'Retour aux magasins',
                ok: 'Ok',
                search: 'Chercher',
                language: 'Langue',
                wrong_key: 'Mauvaise clé!',
                approve: 'Approuver',
                ready: 'Prêt',
                clear_filters: 'Effacer les filtres',
                clear: 'Effacer',
                filters: 'Filtres',
                nothing_was_found: 'Rien n1a été trouvé',
                stores: 'Magasins',
                catalog: 'Catalogue',
                find_store_by_name: 'Trouver un magasin par nom',
                favorites: 'Favoris',
                my_stores: 'Mes magasins',
                login: 'Connexion',
                register: 'S`inscrire',
                wrong_password_or_user_not_registered_in: `Mot de passe incorrect ou utilisateur avec cet e-mail n'est pas enregistré dans`,
                forgot_password: 'Mot de passe oublié?',
                cart: 'Panier',
                vendor_code: 'Code du fournisseur',
                price: 'Prix',
                logout: 'Déconnexion',
                submit: 'Soumettre',
                description: 'Description',
                added: 'Ajouté',
                add_to: 'Ajouter à',
                first_name: 'Prénom',
                last_name: 'Nom de famille',
                phone_number: 'Numéro de téléphone',
                city: 'Ville',
                email: 'E-mail',
                password: 'Mot de passe',
                confirm_password: 'Confirmer le mot de passe',
                company_name: 'Nom de l`entreprise',
                already_registered: 'Déjà inscrit',
                do_want_to_logout: 'Etes-vous sûr de vouloir quitter votre compte?',
                cancel: 'Annuler',
                clear_cart: 'Effacer le panie',
                do_you_want_to_clear_cart: 'Voulez-vous vider le panier?',
                enter_password: 'Entrez le mot de passe',
                enter_email: 'Entrez l`e-mail',
                enter_valid_email: 'Entrez une adresse e-mail valide',
                password_length_min_4_symbols: 'Longueur du mot de passe min 4 symboles',
                passwords_do_not_match: 'Les mots de passe ne correspondent pas',
                enter_first_name: 'Entrez le prénom',
                enter_last_name: 'Entrez le nom de famille',
                enter_phone_number: 'Entrez le numéro de téléphone',
                my_profile: 'Mon profil',
                country: 'Pays',
                e_a: 'e.a.',
                reset_password: 'Réinitialiser le mot de passe',
                close: 'Fermer',
                find_by_vendor_code: 'Rechercher par code fournisseur',
                colors: 'Couleurs',
                color: 'Couleur',
                sizes: 'Tailles',
                contacts: 'Contacts',
                name: 'Nom',
                no_results: 'Aucun résultat',
                loading: 'Chargement',
                phone_length_minimum_7_characters: 'Longueur du téléphone maximum 14 caractères',
                phone_length_max_14_characters: 'Phone length maximum 14 characters',
                size_chart: 'Tableau des tailles',
                load_more: 'Charger plus',
                out_of: 'hors de',
                models: 'modèles',
                in_order_to_use_this_option_you_must_log_in_or_register:
                    'Pour utiliser cette option, vous devez vous connecter ou vous inscrire.',
                you_need_to_login: 'Vous devez vous connecter!',
                sended: 'Envoyé',
                store_key: 'Clé du magasin',
                enter_store_key: 'Entrez la clé du magasin!',
                language_error: 'Erreur de langue !',
                about_store: 'À propos du magasin',
                payments_delivery: 'Paiements et livraison',
                return_exchange: 'Retours et échanges',
                privacy_policy: 'Politique de confidentialité',
                info_title_1: `À propos de brand`,
                info_title_2: 'Paiements et Livraison',
                info_title_3: 'Retours et Échanges',
                info_title_4: 'Politique de Confidentialité',
                message: 'Message',
                info: 'Information',
                item: 'article(s)',
                add_more: 'Ajouter Plus',
                retail_catalog: 'Détail',
                wholesale_catalog: 'Gros',
                web_mob_view: 'Vue Web et Mobile',
                examples: 'Exemples',
                main_advantages: 'Principaux Avantages',
                online_catalog_for_your_business: 'Catalogue en ligne pour votre entreprise',
                request_sended_successfully:
                    'La demande a été envoyée avec succès ! Nous vous contacterons prochainement.',
                first_month_free: 'Premier mois gratuit',
                home: 'Accueil',
                hero_text: `Un catalogue de produits en ligne est un logiciel qui permet de créer des catalogues électroniques avec des descriptions de produits. Il offre des fonctionnalités pour l'ajout facile de produits, la classification, la description, l'ajout de photos et d'autres informations importantes. Les utilisateurs peuvent organiser les produits en différentes catégories, configurer des filtres pratiques, effectuer des recherches pour simplifier la navigation. Il est souvent utilisé par les entreprises pour créer des catalogues commerciaux virtuels, visualiser des projets préliminaires, des boutiques en ligne, présenter des produits lors d'expositions et de présentations.`,
                main_function: `Fonctionnalité Principale`,
                admin_panel: `Panneau d'Administration`,
                admin: `Administrateurs`,
                products_models_photo: `Produits/Modèles/Photos`,
                catalog_lang: `Langues du Catalogue`,
                customization: `Personnalisation`,
                owners: `Pour les Propriétaires`,
                customers: `Pour les Clients`,
                request: `Demande`,
                show_all: `Tout Afficher`,
                unlimited: `Illimité`,
                month: `Mois`,
                i_want: `Je Veux Commander`,
                request_catalog: `Demander un Catalogue`,
                consultation: `Demander une Consultation`,
                request_example: `Demander un Exemple`,
                plan: `Plan`,
                comment: `Commentaire`,
                required_field: 'Champ obligatoire',
                question: 'Question',
                send: 'Envoyer',
                enter_new_password: 'Entrez un nouveau mot de passed',
                clear_form: 'Effacer le formulaire',
                password_changed: 'Mot de passe modifié',
                add_to_cart: 'Ajouter au panier',
                in_cart: 'Dans le panier',
                menu: 'Menu',
                save_delivery_info: 'Enregistrer les informations de livraison',
                available_sizes: 'Tailles disponibles',
                important: 'Important',

                copied_to_clipboard: 'Copié dans le presse-papiers',
                security_check: 'Vérification de sécurité',
            },
        },
        {
            code: 'es',
            string: {
                ...SUPPORTED_APP_LANG?.es,
                ...LONG_PHRASES?.es,
                ...COUNTRIES?.es,
                ...INFORMATION?.es,
                ...HOME_PAGE?.es,
                ...APP_INFO?.es,
                ...ADDITIONAL.es,
                click_here_to_ask_for_wholesale_pricing_information:
                    'Haga clic aquí para solicitar información sobre precios al por mayor',
                get_pricing: 'Obtener precios',
                pricing_form_description: 'Solicitar precios al por mayor e información sobre pedido mínimo',
                want_to_know_all_prices: '¿Quiere conocer todos los precios del catálogo',
                selected_model: 'Modelo seleccionado',
                to: 'a',
                in: 'en',
                no_available_sizes: 'No hay tallas disponibles',
                image_not_loaded: 'Imagen no cargada',
                no_categories_available: 'No hay categorías disponibles',
                back_to_shopping: 'Volver a comprar',
                order_sent_successfully: 'Pedido enviado con éxito',
                deleted_by_owner: 'Eliminado por el propietario',
                details: 'Detalles',
                final_price: 'Precio final',
                quantity: 'Cantidad',
                size: 'Tamaño',
                processed: 'Procesado',
                ordered: 'Ordenado',
                delivered: 'Entregado',
                refunded: 'Reembolsado',
                canceled: 'Cancelado',
                order: 'Orden',
                ordered_date: 'Fecha de orden',
                personal_data: 'Datos personales',
                delivery_data: 'Datos de entrega',
                update: 'Actualizar',
                profile: 'Perfil',
                orders: 'Pedidos',
                clear_favorites: 'Borrar favoritos',
                not_required: 'No requerido',
                excluding_delivery: 'Excluyendo entrega',
                total: 'total',
                delivery_address: 'Dirección de entrega',
                delivery_information: 'Información de entrega',
                confirm_order: 'Confirmar pedido',
                no_sizes_added: 'No se agregaron tamaños',
                delete: 'Eliminar',
                select_sizes: 'Seleccionar tamaños',
                show: 'Mostrado',

                languages: 'Idiomas',
                back_to_stores: 'Volver a las tiendas',
                ok: 'Ok',
                search: 'Buscar',
                language: 'Idioma',
                wrong_key: 'Clave incorrecta!',
                approve: 'Aprobar',
                ready: 'Listo',
                clear_filters: 'Borrar filtros',
                clear: 'Borrar',
                filters: 'Filtros',
                nothing_was_found: 'No se encontró nada',
                stores: 'Tiendas',
                catalog: 'Catálogo',
                find_store_by_name: 'Buscar tienda por nombre',
                favorites: 'Favoritos',
                my_stores: 'Mis tiendas',
                login: 'Iniciar sesión',
                register: 'Registrarse',
                wrong_password_or_user_not_registered_in:
                    'Contraseña incorrecta o usuario con este correo electrónico no está registrado en',
                forgot_password: 'Olvidaste tu contraseña?',
                cart: 'Carrito',
                vendor_code: 'Código de proveedor',
                price: 'Precio',
                logout: 'Cerrar sesión',
                submit: 'Enviar',
                description: 'Descripción',
                added: 'Agregado',
                first_name: 'Nombre de pila',
                last_name: 'Apellido',
                phone_number: 'Número de teléfono',
                city: 'Ciudad',
                email: 'Correo electrónico',
                password: 'Contraseña',
                confirm_password: 'Confirmar contraseña',
                company_name: 'Nombre de empresa',
                already_registered: 'Ya registrado',
                do_want_to_logout: 'Estás seguro de que quieres salir de tu cuenta?',
                cancel: 'Cancelar',
                clear_cart: 'Borrar carrito',
                do_you_want_to_clear_cart: 'Quieres borrar el carrito?',
                enter_password: 'Ingresar contraseña',
                enter_email: 'Ingresar correo electrónico',
                enter_valid_email: 'Ingrese un correo electrónico válido',
                password_length_min_4_symbols: 'Longitud mínima de la contraseña 4 símbolos',
                passwords_do_not_match: 'Las contraseñas no coinciden',
                enter_first_name: 'Ingrese el nombre',
                enter_last_name: 'Ingrese el apellido',
                enter_phone_number: 'Ingrese el número de teléfono',
                my_profile: 'Mi perfil',
                country: 'País',
                e_a: 'e.a.',
                reset_password: 'Restablecer contraseña',
                close: 'Cerca',
                find_by_vendor_code: 'Buscar por código de proveedor',
                colors: 'Colores',
                color: 'Color',
                sizes: 'Tamaños',
                contacts: 'Contactos',
                name: 'Nombre',
                no_results: 'Sin resultados',
                loading: 'Cargando',
                phone_length_minimum_7_characters: 'Longitud mínima del teléfono 7 caracteres',
                phone_length_max_14_characters: 'Phone length maximum 14 characters',
                size_chart: 'Tabla de tamaños',
                load_more: 'Cargar más',
                out_of: 'fuera de',
                models: 'modelos',
                in_order_to_use_this_option_you_must_log_in_or_register:
                    'Para utilizar esta opción, debe iniciar sesión o registrarse.',
                you_need_to_login: 'Necesitas iniciar sesión!',
                sended: 'Enviado',
                store_key: 'Guardar clave',
                enter_store_key: 'Ingrese la clave de la tienda!',
                language_error: 'Error de idioma!',
                about_store: 'Acerca de la tienda',
                payments_delivery: 'Pagos y envío',
                return_exchange: 'Devoluciones y cambios',
                privacy_policy: 'Política de privacidad',
                info_title_1: `Acerca de brand`,
                info_title_2: 'Pagos y Entrega',
                info_title_3: 'Devoluciones y Cambios',
                info_title_4: 'Política de Privacidad',
                message: 'Mensaje',
                info: 'Información',
                item: 'artículo(s)',
                add_more: 'Agregar Más',
                retail_catalog: 'Minorista',
                wholesale_catalog: 'Mayorista',
                web_mob_view: 'Vista Web y Móvil',
                examples: 'Ejemplos',
                main_advantages: 'Principales Ventajas',
                online_catalog_for_your_business: 'Catálogo en línea para su negocio',
                request_sended_successfully:
                    '¡La solicitud se ha enviado correctamente! Nos pondremos en contacto con usted en breve.',
                first_month_free: 'Primer mes gratis',
                home: 'Inicio',
                hero_text: `Un catálogo de productos en línea es una solución de software que permite crear catálogos electrónicos con descripciones de productos. Ofrece funciones para agregar productos fácilmente, clasificarlos, describirlos, agregar fotos y otra información importante. Los usuarios pueden organizar productos en diferentes categorías, configurar filtros convenientes, realizar búsquedas para simplificar la navegación. A menudo lo utilizan las empresas para crear catálogos comerciales virtuales, visualizar proyectos preliminares, tiendas en línea, presentar productos en exposiciones y presentaciones.`,
                main_function: `Funcionalidad Principal`,
                admin_panel: `Panel de Administración`,
                admin: `Administradores`,
                products_models_photo: `Productos/Modelos/Fotos`,
                catalog_lang: `Idiomas del Catálogo`,
                customization: `Personalización`,
                owners: `Para Propietarios`,
                customers: `Para Clientes`,
                request: `Solicitud`,
                show_all: `Mostrar Todo`,
                unlimited: `Ilimitado`,
                month: `Mes`,
                i_want: `Quiero Ordenar`,
                request_catalog: `Solicitar Catálogo`,
                consultation: `Solicitar Consulta`,
                request_example: `Solicitar Ejemplo`,
                plan: `Plan`,
                comment: `Comentario`,
                required_field: 'Campo obligatorio',
                question: 'Pregunta',
                send: 'Enviar',
                enter_new_password: 'Introduzca una nueva contraseña',
                clear_form: 'Limpiar formulario',
                password_changed: 'Contraseña cambiada',
                add_to_cart: 'Añadir al carrito',
                in_cart: 'En el carrito',
                menu: 'Menú',
                save_delivery_info: 'Guardar información de entrega',
                available_sizes: 'Tamaños disponibles',
                important: 'Importante',

                copied_to_clipboard: 'Copiado al portapapeles',
                security_check: 'Verificación de seguridad',
            },
        },
        {
            code: 'ua',
            string: {
                ...SUPPORTED_APP_LANG?.ua,
                ...LONG_PHRASES?.ua,
                ...COUNTRIES?.ua,
                ...INFORMATION?.ua,
                ...HOME_PAGE?.ua,
                ...APP_INFO?.ua,
                ...ADDITIONAL.ua,
                click_here_to_ask_for_wholesale_pricing_information:
                    'Клiкнiть тут, щоб запитати інформацію про оптові ціни',
                get_pricing: 'Отримати ціни',
                pricing_form_description: 'Запит оптових цін та інформації про мінімальне замовлення',
                want_to_know_all_prices: 'Хочете дізнатися всі ціни з каталогу',
                selected_model: 'Обрана модель',

                to: 'до',
                in: 'в',
                no_available_sizes: 'Немає доступних розмірів',
                image_not_loaded: 'Зображення не завантажено',
                no_categories_available: 'Немає доступних категорій',
                back_to_shopping: 'Назад до покупок',
                order_sent_successfully: 'Замовлення успішно відправлено',
                deleted_by_owner: 'Видалено власником',
                details: 'Подробиці',
                final_price: 'Остаточна ціна',
                quantity: 'Кількість',
                size: 'Розмiр',
                processed: 'Обробелено',
                ordered: 'Замовлено',
                delivered: 'Доставлено',
                refunded: 'Повернено',
                canceled: 'Скасовано',
                order: 'Замовлення',
                ordered_date: 'Дата замовлення',
                personal_data: 'Особисті дані',
                delivery_data: 'Дані доставки',
                update: 'Оновити',
                profile: 'Профіль',
                orders: 'Замовлення',
                clear_favorites: 'Очистити вибране',
                not_required: 'Не обов`язково',
                excluding_delivery: 'Без доставки',
                total: 'Всього',
                delivery_address: 'Адреса доставки',
                delivery_information: 'Дані доставки',
                confirm_order: 'Пiдтвердити замовлення',
                no_sizes_added: 'Розміри не додані',
                delete: 'Видалити',
                select_sizes: 'Оберіть розміри',
                shown: 'Зображено',
                languages: 'Мови',
                back_to_stores: 'Повернутися до магазинiв',
                ok: 'Ок',
                search: 'Знайти',
                language: 'Мова',
                wrong_key: 'Неправильний ключ!',
                approve: 'Пiдтвердити',
                ready: 'Готово',
                clear_filters: 'Очистити фільтри',
                clear: 'Очистити',
                filters: 'Фільтри',
                nothing_was_found: 'Нічого не знайдено',
                stores: 'Магазини',
                catalog: 'Каталог',
                find_store_by_name: 'Знайти магазин по назві',
                favorites: 'Уподобане',
                my_stores: 'Мої магазини',
                login: 'Вхід',
                register: 'Зареєструватися',
                wrong_password_or_user_not_registered_in:
                    'Невiрний пароль або користувач з такою поштою не зареєстрований в',
                forgot_password: 'Забули пароль?',
                cart: 'Кошик',
                vendor_code: 'Артикул',
                price: 'Ціна',
                logout: 'Вийти',
                submit: 'Підтвердити',
                description: 'Опис',
                added: 'Додано',
                add_to: 'Додати до',
                first_name: `Ім'я`,
                last_name: 'Прізвище',
                phone_number: 'Номер телефону',
                city: 'Місто',
                email: 'Електронна пошта',
                password: 'Пароль',
                confirm_password: 'Підтвердити пароль',
                company_name: 'Назва компанії',
                already_registered: 'Вже зареєстровані',
                do_want_to_logout: 'Ви дійсно хочете залишити обліковий запис?',
                cancel: 'Скасувати',
                clear_cart: 'Очистити кошик',
                do_you_want_to_clear_cart: 'Бажаєте очистити кошик?',
                enter_password: 'Введіть пароль',
                enter_email: 'Введіть електронну пошту',
                enter_valid_email: 'Введіть дійсну електронну пошту',
                password_length_min_4_symbols: 'Довжина пароля не менше 4 символів',
                passwords_do_not_match: 'Паролі не збігаються',
                enter_first_name: `Введіть ім'я`,
                enter_last_name: 'Введіть прізвище',
                enter_phone_number: 'Введіть номер телефону',
                my_profile: 'Профіль',
                country: 'Країна',
                e_a: 'од.',
                reset_password: 'Скинути пароль',
                close: 'Закрити',
                find_by_vendor_code: 'Знайти модель за артикулом',
                colors: 'Кольори',
                color: 'Колiр',
                sizes: 'Розміри',
                contacts: 'Контакти',
                name: 'Назва',
                no_results: 'Немає результатів',
                loading: 'Завантаження',
                phone_length_minimum_7_characters: 'Довжина телефону не менше 7 символів',
                phone_length_max_14_characters: 'Максимальна довжина телефону 14 символів',
                size_chart: 'Таблиця розмірів',
                load_more: 'Завантажити більше',
                out_of: 'з',
                models: 'моделей',
                in_order_to_use_this_option_you_must_log_in_or_register:
                    'Щоб скористатися цією опцією, ви повинні увійти або зареєструватися.',
                you_need_to_login: 'Потрібно увійти!',
                sended: 'Надіслано',
                store_key: 'Ключ магазину',
                enter_store_key: 'Введіть ключ магазину!',
                language_error: 'Помилка мови!',
                about_store: 'Про магазин',
                payments_delivery: 'Оплата та доставка',
                return_exchange: 'Повернення та обмін',
                privacy_policy: 'Політика конфіденційності',
                info_title_1: `Про brand`,
                info_title_2: 'Платежі та Доставка',
                info_title_3: 'Повернення та Обмін',
                info_title_4: 'Політика конфіденційності',
                message: 'Повідомлення',
                info: 'Інформація',
                item: 'товар(и)',
                add_more: 'Додати Більше',
                retail_catalog: 'Роздрібний',
                wholesale_catalog: 'Оптовий',
                web_mob_view: 'Перегляд в Мережі та на Мобільному',
                examples: 'Приклади',
                main_advantages: 'Основні Переваги',
                online_catalog_for_your_business: 'Онлайн-каталог для вашого бізнесу',
                request_sended_successfully: `Запит успішно надіслано! Ми зв'яжемося з вами найближчим часом.`,
                first_month_free: 'Перший місяць безкоштовно',
                home: 'Головна',
                hero_text: `Онлайн-каталог товарів - це програмне рішення, яке дозволяє створювати електронні каталоги з описом товарів. Він пропонує функції для легкого додавання товарів, класифікації, опису, додавання фотографій та іншої важливої інформації. Користувачі можуть організовувати товари в різні категорії, налаштовувати зручні фільтри, виконувати пошук для спрощення навігації. Його часто використовують компанії для створення віртуальних торгових каталогів, візуалізації проектів, онлайн-магазинів, презентації товарів на виставках та презентаціях.`,
                main_function: `Основні Функції`,
                admin_panel: 'Панель Адміністратора',
                admin: 'Адміністратори',
                products_models_photo: 'Продукти/Моделі/Фото',
                catalog_lang: 'Мови Каталогу',
                customization: 'Персоналізація',
                owners: 'Для Власників',
                customers: 'Для Клієнтів',
                request: 'Запит',
                show_all: 'Показати Все',
                unlimited: 'Без обмежень',
                month: 'Місяць',
                i_want: 'Хочу Замовити',
                request_catalog: 'Замовити Каталог',
                consultation: 'Замовити Консультацію',
                request_example: 'Замовити Приклад',
                plan: 'План',
                comment: 'Коментар',
                required_field: `Обов'язкове поле`,
                question: 'Питання',
                send: 'Надіслати',
                enter_new_password: 'Введіть новий пароль',
                clear_form: 'Очистити форму',
                password_changed: 'Пароль змінено',
                add_to_cart: 'Додати в кошик',
                in_cart: 'У кошику',
                menu: 'Меню',
                save_delivery_info: 'Зберегти інформацію про доставку',
                available_sizes: 'Доступні розміри',
                important: 'Важливо',

                copied_to_clipboard: 'Скопійовано до буферу обміну',
                security_check: 'Перевірка безпеки',
            },
        },
        {
            code: 'pl',
            string: {
                ...SUPPORTED_APP_LANG?.pl,
                ...LONG_PHRASES?.pl,
                ...COUNTRIES?.pl,
                ...INFORMATION?.pl,
                ...HOME_PAGE?.pl,
                ...APP_INFO?.pl,
                ...ADDITIONAL.pl,
                click_here_to_ask_for_wholesale_pricing_information:
                    'Kliknij tutaj, aby zapytać o informacje dotyczące cen hurtowych',
                get_pricing: 'Zdobądź ceny',
                pricing_form_description: 'Zapytaj o ceny hurtowe i informacje dotyczące minimalnego zamówienia',
                want_to_know_all_prices: 'Chcesz poznać wszystkie ceny z katalogu',
                selected_model: 'Wybrany model',

                to: 'do',
                in: 'w',
                no_available_sizes: 'Brak dostępnych rozmiarów',
                image_not_loaded: 'Obraz nie został załadowany',
                no_categories_available: 'Brak dostępnych kategorii',
                back_to_shopping: 'Powrót do zakupów',
                order_sent_successfully: 'Zamówienie zostało wysłane pomyślnie',
                deleted_by_owner: 'Usunięte przez właściciela',
                details: 'Detale',
                final_price: 'Cena ostateczna',
                quantity: 'Ilość',
                size: 'Rozmiar',
                processed: 'Przetworzone',
                ordered: 'Zamówiłem',
                delivered: 'Dostarczone',
                refunded: 'Zwrócony',
                canceled: 'Anulowane',
                order: 'Zamówienia',
                ordered_date: 'Data zamówienia',
                personal_data: 'Dane Osobowe',
                delivery_data: 'Dane dostawy',
                update: 'Aktualizacja',
                profile: 'Profil',
                orders: 'Zamówienia',
                clear_favorites: 'Wyczyść ulubione',
                not_required: 'Nie wymagane',
                excluding_delivery: 'Z wyłączeniem dostawy',
                total: 'Całkowity',
                delivery_address: 'Adres dostawy',
                delivery_information: 'Szczegóły dostawy',
                confirm_order: 'Potwierdzić zamówienie',
                no_sizes_added: 'Nie dodano żadnych rozmiarów',
                delete: 'Usuwać',
                select_sizes: 'Wybierz rozmiary',
                shown: 'Pokazano',
                languages: 'Języki',
                back_to_stores: 'Wróć do sklepów',
                ok: 'Ok',
                search: 'Szukaj',
                language: 'Język',
                wrong_key: 'Niewłaściwy klucz!',
                approve: 'Zatwierdzić',
                ready: 'Gotowy',
                clear_filters: 'Wyczyść filtry',
                clear: 'Wyczyść',
                filters: 'Filtry',
                nothing_was_found: 'Nic nie zostało znalezione',
                stores: 'Sklepy',
                catalog: 'Katalog',
                find_store_by_name: 'Znajdź sklep według nazwy',
                favorites: 'Ulubione',
                my_stores: 'Moje sklepy',
                login: 'Zaloguj się',
                register: 'Zarejestruj się',
                wrong_password_or_user_not_registered_in:
                    'Nieprawidłowe hasło lub użytkownik z tym adresem e-mail nie jest zarejestrowany w',
                forgot_password: 'Zapomniałem hasła?',
                cart: 'Koszyk',
                vendor_code: 'Kod',
                price: 'Cena',
                logout: 'Wyloguj',
                submit: 'Składać',
                description: 'Opis',
                added: 'Dodano',
                add_to: 'Dodaj do',
                first_name: 'Imię',
                last_name: 'Nazwisko',
                phone_number: 'Numer telefonu',
                city: 'Miasto',
                email: 'E-mail',
                password: 'Hasło',
                confirm_password: 'Potwierdź hasło',
                company_name: 'Nazwa firmy',
                already_registered: 'Już zarejestrowany',
                do_want_to_logout: 'Czy na pewno chcesz opuścić swoje konto?',
                cancel: 'Anuluj',
                clear_cart: 'Wyczyść koszyk',
                do_you_want_to_clear_cart: 'Czy chcesz wyczyścić koszyk?',
                enter_password: 'Wprowadź hasło',
                enter_email: 'Wpisz email',
                enter_valid_email: 'Wprowadź prawidłowy adres e-mail',
                password_length_min_4_symbols: 'Długość hasła min. 4 znaków',
                passwords_do_not_match: 'Hasła się nie zgadzają',
                enter_first_name: 'Wpisz imię',
                enter_last_name: 'Wpisz nazwisko',
                enter_phone_number: 'Wprowadź numer telefonu',
                my_profile: 'Mój profil',
                country: 'Kraj',
                e_a: 'e.a.',
                reset_password: 'Zresetuj hasło',
                close: 'Zamknij',
                find_by_vendor_code: 'Znajdź według kodu dostawcy',
                colors: 'Kolory',
                color: 'Kolor',
                sizes: 'Rozmiary',
                contacts: 'Kontakty',
                name: 'Nazwa',
                no_results: 'Brak wyników',
                loading: 'Ładowanie',
                phone_length_minimum_7_characters: 'Maksymalna długość telefonu to 14 znaków',
                phone_length_max_14_characters: 'Phone length maximum 14 characters',
                size_chart: 'Tabela rozmiarów',
                load_more: 'Załaduj więcej',
                out_of: 'ze',
                models: 'modeli',
                in_order_to_use_this_option_you_must_log_in_or_register:
                    'Aby skorzystać z tej opcji należy się zalogować lub zarejestrować.',
                you_need_to_login: 'Musisz się zalogować!',
                sended: 'Wysłano',
                store_key: 'Klucz sklepu',
                enter_store_key: 'Wprowadź klucz sklepu',
                language_error: 'Błąd językowy!',
                about_store: 'O sklepie',
                payments_delivery: 'Płatności i dostawa',
                return_exchange: 'Zwroty i wymiany',
                privacy_policy: 'Polityka prywatności',
                info_title_1: `O brand`,
                info_title_2: 'Płatności i Dostawa',
                info_title_3: 'Zwroty i Wymiany',
                info_title_4: 'Polityka Prywatności',
                message: 'Wiadomość',
                info: 'Informacje',
                item: 'produkt(y)',
                add_more: 'Dodaj Więcej',
                retail_catalog: 'Detaliczny',
                wholesale_catalog: 'Hurtowy',
                web_mob_view: 'Widok Web i Mobilny',
                examples: 'Przykłady',
                main_advantages: 'Główne Zalety',
                online_catalog_for_your_business: 'Katalog Online dla Twojej Firmy',
                request_sended_successfully: 'Zapytanie zostało wysłane pomyślnie! Skontaktujemy się z Tobą wkrótce.',
                first_month_free: 'Pierwszy miesiąc gratis',
                home: 'Strona Główna',
                hero_text: `Katalog produktów online to oprogramowanie, które umożliwia tworzenie elektronicznych katalogów z opisami produktów. Oferuje funkcje ułatwiające dodawanie produktów, ich klasyfikowanie, opisywanie, dodawanie zdjęć i innych istotnych informacji. Użytkownicy mogą organizować produkty w różne kategorie, konfigurować wygodne filtry, wykonywać wyszukiwania w celu ułatwienia nawigacji. Jest często używany przez firmy do tworzenia wirtualnych katalogów handlowych, wizualizacji projektów wstępnych, sklepów internetowych, prezentowania produktów na wystawach i prezentacjach.`,
                main_function: `Główne Funkcje`,
                admin_panel: 'Panel Administratora',
                admin: 'Administratorzy',
                products_models_photo: 'Produkty/Modele/Zdjęcia',
                catalog_lang: 'Języki Katalogu',
                customization: 'Personalizacja',
                owners: 'Dla Właścicieli',
                customers: 'Dla Klientów',
                request: 'Zapytanie',
                show_all: 'Pokaż Wszystko',
                unlimited: 'Bez Limitów',
                month: 'Miesiąc',
                i_want: 'Chcę Zamówić',
                request_catalog: 'Zamów Katalog',
                consultation: 'Zamów Konsultację',
                request_example: 'Zamów Przykład',
                plan: 'Plan',
                comment: 'Komentarz',
                required_field: 'Pole wymagane',
                question: 'Pytanie',
                send: 'Wyślij',
                enter_new_password: 'Wprowadź nowe hasło',
                clear_form: 'Wyczyść formularz',
                password_changed: 'Hasło zostało zmienione',
                add_to_cart: 'Dodaj do koszyka',
                in_cart: 'W koszyku',
                menu: 'Menu',
                save_delivery_info: 'Zapisz informacje o dostawie',
                available_sizes: 'Dostępne rozmiary',
                important: 'Ważne',

                copied_to_clipboard: 'Skopiowane do schowka',
                security_check: 'Kontrola bezpieczeństwa',
            },
        },
        {
            code: 'cz',
            string: {
                ...SUPPORTED_APP_LANG?.cz,
                ...LONG_PHRASES?.cz,
                ...COUNTRIES?.cz,
                ...INFORMATION?.cz,
                ...HOME_PAGE?.cz,
                ...APP_INFO?.cz,
                ...ADDITIONAL.cz,
                click_here_to_ask_for_wholesale_pricing_information:
                    'Klikněte zde pro požádání o informace o velkoobchodních cenách',
                get_pricing: 'Získat ceny',
                pricing_form_description: 'Žádat o velkoobchodní ceny a informace o minimálním objednávkovém množství',
                want_to_know_all_prices: 'Chcete vědět všechny ceny z katalogu',
                selected_model: 'Vybraný model',

                to: 'do',
                in: 'v',
                no_available_sizes: 'Žádné dostupné velikosti',
                image_not_loaded: 'Obrázek není načten',
                no_categories_available: 'Nejsou k dispozici žádné kategorie',
                back_to_shopping: 'Zpět na nákupy',
                order_sent_successfully: 'Objednávka byla úspěšně odeslána',
                deleted_by_owner: 'Smazáno vlastníkem',
                details: 'Podrobnosti',
                final_price: 'Konečná cena',
                quantity: 'Množství',
                size: 'Velikost',
                processed: 'Zpracováno',
                ordered: 'Objednáno',
                delivered: 'Doručeno',
                refunded: 'Refunded',
                canceled: 'Zrušeno',
                order: 'Objednávky',
                ordered_date: 'Datum objednávky',
                personal_data: 'Osobní údaje',
                delivery_data: 'Delivery Data',
                update: 'Aktualizace',
                profile: 'Profil',
                orders: 'Objednávky',
                clear_favorites: 'Vymazat oblíbené',
                not_required: 'Není požadováno',
                excluding_delivery: 'S výjimkou doručení',
                total: 'Celkový',
                delivery_address: 'Adres dostawy',
                delivery_information: 'Detaily objednávky',
                confirm_order: 'Potvrďte objednávku',
                no_sizes_added: 'Nebyly přidány žádné velikosti',
                delete: 'Vymazat',
                select_sizes: 'Vyberte velikosti',
                shown: 'Zobrazeno',
                languages: 'Jazyky',
                back_to_stores: 'Vraťte se do obchodů',
                ok: 'Ok',
                search: 'Nalézt',
                language: 'Jazyk',
                wrong_key: 'Špatný klíč!',
                approve: 'Schvalovat',
                ready: 'Připraven',
                clear_filters: 'Vymazat filtry',
                clear: 'Vymazat',
                filters: 'Filtry',
                nothing_was_found: 'Nic nebylo nalezeno',
                stores: 'Obchody',
                catalog: 'Katalog',
                find_store_by_name: 'Najděte obchod podle názvu',
                favorites: 'Oblíbené',
                my_stores: 'Moje obchody',
                login: 'Přihlásit',
                register: 'Registrovat',
                wrong_password_or_user_not_registered_in:
                    'Nesprávné heslo nebo uživatel s tímto e-mailem není registrován v',
                forgot_password: 'Zapomenuté heslo?',
                cart: 'Košík',
                vendor_code: 'Kód',
                price: 'Cena',
                logout: 'Odhlásit',
                submit: 'Předložit',
                description: 'Popis',
                added: 'Přidáno',
                add_to: 'Přidat do',
                first_name: 'Křestní jméno',
                last_name: 'Příjmení',
                phone_number: 'Telefonní číslo',
                city: 'Město',
                email: 'Email',
                password: 'Heslo',
                confirm_password: 'Potvrdit heslo',
                company_name: 'Název společnosti',
                already_registered: 'Již zaregistrován',
                do_want_to_logout: 'Opravdu chcete opustit svůj účet?',
                cancel: 'Cancel',
                clear_cart: 'Vymazat košík',
                do_you_want_to_clear_cart: 'Chcete vymazat košík?',
                enter_password: 'Zadejte heslo',
                enter_email: 'Zadejte e-mail',
                enter_valid_email: 'Zadejte platný e-mail',
                password_length_min_4_symbols: 'Délka hesla min 4 symbolů',
                passwords_do_not_match: 'Hesla se neshodují',
                enter_first_name: 'Zadejte křestní jméno',
                enter_last_name: 'Zadejte příjmení',
                enter_phone_number: 'Zadejte telefonní číslo',
                my_profile: 'Můj profil',
                country: 'Země',
                e_a: 'e.a.',
                reset_password: 'Resetovat heslo',
                close: 'Zavřít',
                find_by_vendor_code: 'Najít podle kódu dodavatele',
                colors: 'Barvy',
                color: 'Barva',
                sizes: 'Velikosti',
                contacts: 'Kontakty',
                name: 'Jméno',
                no_results: 'Žádné výsledky',
                loading: 'Načítání',
                phone_length_minimum_7_characters: 'Minimální délka telefonu 7 znaků',
                phone_length_max_14_characters: 'Maximální délka telefonu je 14 znaků',
                size_chart: 'Tabulka velikostí',
                load_more: 'Načíst více',
                out_of: 'ze',
                models: 'modelů',
                in_order_to_use_this_option_you_must_log_in_or_register:
                    'Abyste mohli tuto možnost využít, musíte se přihlásit nebo zaregistrovat.',
                you_need_to_login: 'Musíte se přihlásit!',
                sended: 'Odesláno',
                store_key: 'Klíč obchodu',
                enter_store_key: 'Zadejte klíč obchodu!',
                language_error: 'Jazyková chyba!',
                about_store: 'O obchodě',
                payments_delivery: 'Platby a doručení',
                return_exchange: 'Vrácení a výměna',
                privacy_policy: 'Zásady ochrany osobních údajů',
                info_title_1: `O brand`,
                info_title_2: 'Platby a Doručení',
                info_title_3: 'Vrácení a Výměna',
                info_title_4: 'Zásady Ochrany Soukromí',
                message: 'Zpráva',
                info: 'Informace',
                item: 'produkt(y)',
                add_more: 'Přidat Další',
                retail_catalog: 'Maloobchodní',
                wholesale_catalog: 'Velkoobchodní',
                web_mob_view: 'Zobrazení Web a Mobil',
                examples: 'Příklady',
                main_advantages: 'Hlavní Výhody',
                online_catalog_for_your_business: 'Online Katalog Pro Vaši Firmu',
                request_sended_successfully: 'Žádost byla odeslána úspěšně! Brzy se s Vámi spojíme.',
                first_month_free: 'První Měsíc Zdarma',
                home: 'Domů',
                hero_text: `Online katalog produktů je softwarové řešení, které umožňuje vytvářet elektronické katalogy s popisy produktů. Nabízí funkce pro snadné přidávání produktů, jejich klasifikaci, popis, přidávání fotografií a dalších důležitých informací. Uživatelé mohou produkty organizovat do různých kategorií, nastavovat pohodlné filtry, provádět vyhledávání pro zjednodušení navigace. Často ho používají firmy k vytváření virtuálních obchodních katalogů, vizualizaci projektů, online obchodů, prezentaci produktů na výstavách a prezentacích.`,
                main_function: `Hlavní Funkce`,
                admin_panel: 'Panel Administrátora',
                admin: 'Administrátoři',
                products_models_photo: 'Produkty/Modely/Fotky',
                catalog_lang: 'Jazyky Katalogu',
                customization: 'Personalizace',
                owners: 'Pro Vlastníky',
                customers: 'Pro Zákazníky',
                request: 'Žádost',
                show_all: 'Zobrazit Vše',
                unlimited: 'Neomezený',
                month: 'Měsíc',
                i_want: 'Chci Objednat',
                request_catalog: 'Zažádat o Katalog',
                consultation: 'Zažádat o Konzultaci',
                request_example: 'Zažádat o Příklad',
                plan: 'Plán',
                comment: 'Komentář',
                required_field: 'Povinné pole',
                question: 'Otázka',
                send: 'Odeslat',
                enter_new_password: 'Zadejte nové heslo',
                clear_form: 'Vymazat formulář"',
                password_changed: 'Heslo bylo změněno',
                add_to_cart: 'Přidat do košíku',
                in_cart: 'V košíku',
                menu: 'Menu',
                save_delivery_info: 'Uložit informace o doručení',
                available_sizes: 'Dostupné velikosti',
                important: 'Důležité',

                copied_to_clipboard: 'Zkopírováno do schránky',
                security_check: 'Bezpečnostní kontrola',
            },
        },
        {
            code: 'ru',
            string: {
                ...SUPPORTED_APP_LANG?.ru,
                ...LONG_PHRASES?.ru,
                ...COUNTRIES?.ru,
                ...INFORMATION?.ru,
                ...HOME_PAGE?.ru,
                ...APP_INFO?.ru,
                ...ADDITIONAL.ru,
                click_here_to_ask_for_wholesale_pricing_information:
                    'Нажмите здесь, чтобы запросить информацию о оптовых ценах',
                get_pricing: 'Получить цены',
                pricing_form_description: 'Запросить оптовые цены и информацию о минимальном заказе',
                want_to_know_all_prices: 'Хотите узнать все цены из каталога',
                selected_model: 'Выбранная модель',

                to: 'к',
                in: 'в',
                no_available_sizes: 'Нет доступных размеров',
                image_not_loaded: 'Изображение не загружено',
                no_categories_available: 'Нет доступных категорий',
                back_to_shopping: 'Вернуться к покупкам',
                order_sent_successfully: 'Заказ успешно отправлен',
                deleted_by_owner: 'Удален владельцем',
                details: 'Детально',
                final_price: 'Итоговая Цена',
                quantity: 'Количество',
                size: 'Размер',
                processed: 'Обработано',
                ordered: 'Заказано',
                delivered: 'Доставлено',
                refunded: 'Возвратено',
                canceled: 'Отменено',
                order: 'Заказ',
                ordered_date: 'Дата Заказа',
                personal_data: 'Личные данные',
                delivery_data: 'Данные о доставке',
                update: 'Обновить',
                profile: 'Профиль',
                orders: 'Заказы',
                clear_favorites: 'Очистить избранное',
                not_required: 'Не обязательно',
                excluding_delivery: 'Без доставки',
                total: 'Итого',
                delivery_address: 'Адресс доставки',
                delivery_information: 'Данные доставки',
                confirm_order: 'Подтвердить заказ',
                no_sizes_added: 'Размеры не добавлены',
                delete: 'Удалить',
                select_sizes: 'Выбереите размеры',
                shown: 'Показано',
                languages: 'Языки',
                back_to_stores: 'Вернуться к магазинам',
                ok: 'Ок',
                search: 'Поиск',
                language: 'Язык',
                wrong_key: 'Неверный ключ!',
                approve: 'Подтвердить',
                ready: 'Готово',
                clear_filters: 'Очистить филтры',
                clear: 'Очистить',
                filters: 'Фильтры',
                nothing_was_found: 'Ничего не найдено',
                stores: 'Магазины',
                catalog: 'Каталог',
                find_store_by_name: 'Найти магазин по названию',
                favorites: 'Избранное',
                my_stores: 'Мои магазины',
                login: 'Вход',
                register: 'Регистрация',
                wrong_password_or_user_not_registered_in:
                    'Неверный пароль или пользователь с такой почтой не зарегестрирован в',
                forgot_password: 'Забыл пароль?',
                cart: 'Корзина',
                vendor_code: 'Артикул',
                price: 'Цена',
                logout: 'Выход',
                submit: 'Подтвердить',
                description: 'Описание',
                added: 'Добавлено',
                add_to: 'Добавить в',
                first_name: 'Имя',
                last_name: 'Фамилия',
                phone_number: 'Номер телефона',
                city: 'Город',
                email: 'Email',
                password: 'Пароль',
                confirm_password: 'Подтвердить пароль',
                company_name: 'Имя компании',
                already_registered: 'Уже зарегестрирован',
                do_want_to_logout: 'Вы действительно хотите покинуть аккаунт?',
                cancel: 'Отмена',
                clear_cart: 'Очистить корзину',
                do_you_want_to_clear_cart: 'Вы хотите очистить корзину?',
                enter_password: 'Введите пароль',
                enter_email: 'Введите email',
                enter_valid_email: 'Введите корректный email',
                password_length_min_4_symbols: 'Пароль должен быть не менее 4 символов',
                passwords_do_not_match: 'Пароли не совпадают',
                enter_first_name: 'Введите имя',
                enter_last_name: 'Введите фамилию',
                enter_phone_number: 'Введите номер телефона',
                my_profile: 'Профиль',
                country: 'Страна',
                e_a: 'ед.',
                reset_password: 'Сбросить пароль',
                close: 'Закрыть',
                find_by_vendor_code: 'Найти по артикулу',
                colors: 'Цвета',
                color: 'Цвет',
                sizes: 'Размеры',
                contacts: 'Контакты',
                name: 'Имя',
                no_results: 'Нет результатов',
                loading: 'Загрузка',
                phone_length_minimum_7_characters: 'Минимум 7 символов',
                phone_length_max_14_characters: 'Максимальная длина телефона 14 символов',
                size_chart: 'Таблица размеров',
                load_more: 'Загрузить еще',
                out_of: 'из',
                models: 'моделей',
                in_order_to_use_this_option_you_must_log_in_or_register:
                    'Для того, чтобы воспользоваться этой опцией, вам необходимо войти или зарегистрироваться.',
                you_need_to_login: 'Необходимо войти!',
                sended: 'Отправлено',
                store_key: 'Ключ магазина',
                enter_store_key: 'Введите ключ магазина!',
                language_error: 'Ошибка языка!',
                about_store: 'О магазине',
                payments_delivery: 'Оплата и доставка',
                return_exchange: 'Возврат и обмен',
                privacy_policy: 'Политика конфиденциальности',
                info_title_1: `О brand`,
                info_title_2: 'Платежи и Доставка',
                info_title_3: 'Возвраты и Обмен',
                info_title_4: 'Политика конфиденциальности',
                message: 'Сообщение',
                info: 'Информация',
                item: 'товар(ы)',
                add_more: 'Добавить Больше',
                retail_catalog: 'Розничный',
                wholesale_catalog: 'Оптовый',
                web_mob_view: 'Вид Web и Мобильный',
                examples: 'Примеры',
                main_advantages: 'Основные Преимущества',
                online_catalog_for_your_business: 'Онлайн-каталог для вашего бизнеса',
                request_sended_successfully: 'Запрос успешно отправлен! Мы свяжемся с вами в ближайшее время.',
                first_month_free: 'Первый месяц бесплатно',
                home: 'Главная',
                hero_text: `Онлайн-каталог товаров - это программное решение, которое позволяет создавать электронные каталоги с описаниями товаров. Он предлагает функции для легкого добавления товаров, их классификации, описания, добавления фотографий и другой важной информации. Пользователи могут организовывать товары в разные категории, настраивать удобные фильтры, выполнять поиск для упрощения навигации. Его часто используют компании для создания виртуальных торговых каталогов, визуализации проектов, онлайн-магазинов, презентации товаров на выставках и презентациях.`,
                main_function: `Основные Функции`,
                admin_panel: 'Панель Администратора',
                admin: 'Администраторы',
                products_models_photo: 'Продукты/Модели/Фото',
                catalog_lang: 'Языки Каталога',
                customization: 'Персонализация',
                owners: 'Для Владельцев',
                customers: 'Для Клиентов',
                request: 'Запрос',
                show_all: 'Показать Все',
                unlimited: 'Без ограничений',
                month: 'Месяц',
                i_want: 'Хочу Заказать',
                request_catalog: 'Заказать Каталог',
                consultation: 'Заказать Консультацию',
                request_example: 'Заказать Пример',
                plan: 'План',
                comment: 'Комментарий',
                required_field: 'Обязательное поле',
                question: 'Вопрос',
                send: 'Отправить',
                enter_new_password: 'Введите новый пароль',
                clear_form: 'Очистить форму',
                password_changed: 'Пароль изменен',
                add_to_cart: 'добавить в корзину',
                in_cart: 'В корзине',
                menu: 'Меню',
                save_delivery_info: 'Сохранить информацию о доставке',
                available_sizes: 'Доступные размеры',
                important: 'Важно',

                copied_to_clipboard: 'Скопировано в буфер обмена',
                security_check: 'Проверка безопасности',
            },
        },
        {
            code: 'kz',
            string: {
                ...SUPPORTED_APP_LANG?.kz,
                ...LONG_PHRASES?.kz,
                ...COUNTRIES?.kz,
                ...INFORMATION?.kz,
                ...HOME_PAGE?.kz,
                ...APP_INFO?.kz,
                ...ADDITIONAL.kz,
                click_here_to_ask_for_wholesale_pricing_information:
                    'Опттық бағалар туралы ақпарат алу үшін мұнда басыңыз',
                get_pricing: 'Бағаларды алу',
                pricing_form_description: 'Опттық бағаларды және минималды тапсырыс туралы ақпарат сұрау',
                want_to_know_all_prices: 'Каталогдың барлық бағаларын білгілеуге тиіссіз боласыз ба',
                selected_model: 'Таңдалған модель',
                to: 'қарай',
                in: 'iшiнде',
                no_available_sizes: 'Қол жетімді өлшемдер жоқ',
                image_not_loaded: 'Сурет жүктелмеді',
                no_categories_available: 'Қол жетімді санаттар жоқ',
                back_to_shopping: 'Саудаға оралу',
                order_sent_successfully: 'Тапсырыс сәтті жіберілді',
                deleted_by_owner: 'Иесімен жойылды',
                details: 'Толығырақ',
                final_price: 'Соңғы баға',
                quantity: 'Саны',
                size: 'Өлшем',
                processed: 'Өңделген',
                ordered: 'Тапсырылған',
                delivered: 'Жеткізілді',
                refunded: 'Қайтарылды',
                canceled: 'Болдырмау',
                order: 'Тапсырыс',
                ordered_date: 'Тапсырыс күні',
                personal_data: 'Жеке деректер',
                delivery_data: 'Жеткізу деректері',
                update: 'Жаңарту',
                profile: 'Профиль',
                orders: 'Тапсырыстар',
                clear_favorites: 'Таңдаулыларды тазалау',
                not_required: 'Қажет емес',
                excluding_delivery: 'Жеткізу есептелмеген',
                total: 'барлығы',
                delivery_address: 'Жеткізу мекенжайы',
                delivery_information: 'Жеткізу ақпараты',
                confirm_order: 'Тапсырысты растау',
                no_sizes_added: 'Өлшемдер қосылмаған',
                delete: 'Жою',
                select_sizes: 'Өлшемдерді таңдау',
                shown: 'Көрсетілген',
                languages: 'Тілдер',
                back_to_stores: 'Дүкендерге қайту',
                ok: 'Жарайды',
                search: 'Іздеу',
                language: 'Тіл',
                wrong_key: 'Қате кілт!',
                approve: 'Мақұлдау',
                ready: 'Дайын',
                clear_filters: 'Сүзгілерді тазалау',
                clear: 'Тазалау',
                filters: 'Сүзгілер',
                nothing_was_found: 'Ештеңе табылған жоқ',
                stores: 'Дүкендер',
                catalog: 'Каталог',
                find_store_by_name: 'Дүкенді атауы бойынша табу',
                favorites: 'Таңдаулылар',
                my_stores: 'Менің дүкендерім',
                login: 'Кіру',
                register: 'Тіркелу',
                wrong_password_or_user_not_registered_in:
                    'Қате құпия сөз немесе бұл электрондық поштаға тіркелген пайдаланушы жоқ',
                forgot_password: 'Құпия сөзді ұмыттыңыз ба?',
                cart: 'Себет',
                vendor_code: 'Өнім коды',
                price: 'Баға',
                logout: 'Шығу',
                submit: 'Жіберу',
                description: 'Сипаттама',
                added: 'Қосылған',
                add_to: 'Қосу',
                first_name: 'Аты',
                last_name: 'Тегі',
                phone_number: 'Телефон нөмірі',
                city: 'Қала',
                email: 'Электрондық пошта',
                password: 'Құпия сөз',
                confirm_password: 'Құпия сөзді растау',
                company_name: 'Компания атауы',
                already_registered: 'Бұрын тіркелген',
                do_want_to_logout: 'Шоттан шығуға сенімдісіз бе?',
                cancel: 'Бас тарту',
                clear_cart: 'Себетті тазалау',
                do_you_want_to_clear_cart: 'Себетті тазалағыңыз келе ме?',
                enter_password: 'Құпия сөзді енгізіңіз',
                enter_email: 'Электрондық поштаны енгізіңіз',
                enter_valid_email: 'Жарамды электрондық поштаны енгізіңіз',
                password_length_min_4_symbols: 'Құпия сөз ұзындығы кемінде 4 таңба',
                passwords_do_not_match: 'Құпия сөздер сәйкес келмейді',
                enter_first_name: 'Атыңызды енгізіңіз',
                enter_last_name: 'Тегіңізді енгізіңіз',
                enter_phone_number: 'Телефон нөміріңізді енгізіңіз',
                my_profile: 'Менің профильім',
                country: 'Ел',
                e_a: 'e.a.',
                reset_password: 'Құпия сөзді қалпына келтіру',
                close: 'Жабу',
                find_by_vendor_code: 'Өнім коды бойынша табу',
                colors: 'Түстер',
                color: 'Түс',
                sizes: 'Өлшемдер',
                contacts: 'Байланыстар',
                name: 'Атауы',
                no_results: 'Нәтиже жоқ',
                loading: 'Жүктелуде',
                phone_length_minimum_7_characters: 'Телефон нөмірінің ұзындығы кемінде 7 таңба',
                phone_length_max_14_characters: 'Телефон нөмірінің ұзындығы ең көп 14 таңба',
                size_chart: 'Өлшемдер кестесі',
                load_more: 'Көбірек жүктеу',
                out_of: 'iшiнен',
                models: 'Үлгілер',
                in_order_to_use_this_option_you_must_log_in_or_register:
                    'Бұл опцияны пайдалану үшін сіз кіруіңіз немесе тіркелуіңіз керек.',
                you_need_to_login: 'Кіруіңіз керек!',
                sended: 'Жіберілді',
                store_key: 'Дүкен кілті',
                enter_store_key: 'Дүкен кілтін енгізіңіз!',
                language_error: 'Тіл қатесі!',
                support: 'Қолдау',
                ask_question: 'Сұрақ қою',
                about_store: 'Дүкен туралы',
                payments_delivery: 'Төлемдер мен жеткізу',
                return_exchange: 'Қайтару және айырбастау',
                privacy_policy: 'Құпиялылық саясаты',
                info_title_1: `brand туралы`,
                info_title_2: 'Төлемдер мен жеткізу',
                info_title_3: 'Қайтару және айырбастау',
                info_title_4: 'Құпиялылық саясаты',
                message: 'Хабарлама',
                info: 'Ақпарат',
                item: 'зат(тар)',
                add_more: 'Көбірек қосу',
                retail_catalog: 'Бөлшек сауда',
                wholesale_catalog: 'Бөлшек сауда',
                web_mob_view: 'Веб және мобильді көрініс',
                examples: 'Үлгілер',
                main_advantages: 'Негізгі артықшылықтар',
                online_catalog_for_your_business: 'Бизнесіңіз үшін онлайн каталог',
                request_sended_successfully: 'Сұрау сәтті жіберілді! Біз сізбен жақын арада байланысамыз.',
                first_month_free: 'Алғашқы ай тегін',
                home: 'Басты бет',
                hero_text: `Онлайн өнім каталогы - бұл өнімдердің сипаттамаларымен электрондық каталогдар жасауға мүмкіндік беретін бағдарламалық шешім. Бұл өнімдерді оңай қосу, жіктеу, сипаттама жасау, суреттер қосу және басқа маңызды ақпараттарды қосу мүмкіндіктерін қамтиды. Пайдаланушылар өнімдерді әртүрлі санаттарға ұйымдастыра алады, ыңғайлы сүзгілер орната алады, навигацияны жеңілдету үшін іздеулер жасай алады. Жиі компаниялар виртуалды сауда каталогдарын, алдын ала жобаларды визуализациялау, онлайн дүкендер, көрмелерде және ұсыныстарда өнімдерді ұсыну үшін пайдаланады.`,
                main_function: `Негізгі функционалдылық`,
                admin_panel: `Әкімшілік тақтасы`,
                admin: `Әкімшілер`,
                products_models_photo: `Өнімдер/Үлгілер/Суреттер`,
                catalog_lang: `Каталог тілдері`,
                customization: `Бейімдеу`,
                owners: `Иелер үшін`,
                customers: `Тұтынушылар үшін`,
                request: `Сұрау`,
                show_all: `Барлығын көрсету`,
                unlimited: `Шексіз`,
                month: `Ай`,
                i_want: `Тапсырыс бергім келеді`,
                request_catalog: `Каталог сұрау`,
                consultation: `Кеңес сұрау`,
                request_example: `Үлгі сұрау`,
                plan: `Жоспар`,
                comment: `Түсініктеме`,
                required_field: 'Міндетті өріс',
                question: 'Сұрақ',
                send: 'Жіберу',
                enter_new_password: 'Жаңа құпия сөзді енгізіңіз',
                clear_form: 'Пішінді тазалау',
                password_changed: 'Құпия сөз өзгертілді',
                add_to_cart: 'Себетке қосу',
                in_cart: 'Себетте',
                menu: 'Мәзірі',
                save_delivery_info: 'Жеткізу туралы ақпаратты сақтау',
                available_sizes: 'Қолжетімді өлшемдер',
                important: 'Маңызды',

                copied_to_clipboard: 'Буферге көшірілді',
                security_check: 'Қауіпсіздік тексеру',
            },
        },
    ];

    useEffect(() => {
        setCurrentLanguage(langList.find(({ code }) => code === lang) || { code: '', string: {} }); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang]);

    return { currentLanguage };
};
