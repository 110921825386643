export const countryList = [
    { code: 'AD', country: 'andorra' },
    { code: 'AL', country: 'albania' },
    { code: 'AT', country: 'austria' },
    { code: 'AZ', country: 'azerbaijan' },
    { code: 'AM', country: 'armenia' },
    { code: 'BY', country: 'belarus' },
    { code: 'BE', country: 'belgium' },
    { code: 'BA', country: 'bosnia_and_herzegovina' },
    { code: 'BG', country: 'bulgaria' },
    { code: 'HR', country: 'croatia' },
    { code: 'CY', country: 'cyprus' },
    { code: 'CZ', country: 'czechia' },
    { code: 'DK', country: 'denmark' },
    { code: 'EE', country: 'estonia' },
    { code: 'FO', country: 'faroe_islands' },
    { code: 'FI', country: 'finland' },
    { code: 'FR', country: 'france' },
    { code: 'GE', country: 'georgia' },
    { code: 'DE', country: 'germany' },
    { code: 'GI', country: 'gibraltar' },
    { code: 'GR', country: 'greece' },
    { code: 'GG', country: 'guernsey' },
    { code: 'HU', country: 'hungary' },
    { code: 'IS', country: 'iceland' },
    { code: 'IT', country: 'italy' },
    { code: 'IE', country: 'ireland' },
    { code: 'KZ', country: 'kazakhstan' },
    { code: 'XK', country: 'kosovo' },
    { code: 'LV', country: 'latvia' },
    { code: 'LI', country: 'liechtenstein' },
    { code: 'LT', country: 'lithuania' },
    { code: 'LU', country: 'luxembourg' },
    { code: 'MT', country: 'malta' },
    { code: 'MD', country: 'moldova' },
    { code: 'MC', country: 'monaco' },
    { code: 'NL', country: 'netherlands' },
    { code: 'MK', country: 'north_macedonia' },
    { code: 'NO', country: 'norway' },
    { code: 'PL', country: 'poland' },
    { code: 'PT', country: 'portugal' },
    { code: 'RO', country: 'romania' },
    { code: 'RU', country: 'russia' },
    { code: 'SM', country: 'san_marino' },
    { code: 'RS', country: 'serbia' },
    { code: 'SK', country: 'slovakia' },
    { code: 'SI', country: 'slovenia' },
    { code: 'ES', country: 'spain' },
    { code: 'SJ', country: 'svalbard_and_jan_mayen' },
    { code: 'SE', country: 'sweden' },
    { code: 'TR', country: 'turkey' },
    { code: 'UA', country: 'ukraine' },
    { code: 'GB', country: 'united_kingdom' },
    { code: 'VA', country: 'vatican_city' },
];

export const TRANSLATED_COUNTRIES = countryList;
